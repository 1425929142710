import React from "react";
import "./common.scss";
import { Routes, Route, NavLink, Navigate } from "react-router-dom";
import useWindowDimensions from "./hooks/useWindowDimensions";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import AboutUs from "./pages/AboutUs/AboutUs";
import Homepage from "./pages/Homepage/Homepage";
import BeSpokeHome from "./pages/BeSpokeHome/BeSpokeHome";
import ContactUs from "./pages/ContactUs/ContactUs";
import VillaEstelle from "./pages/VillaInner/VillaEstelle";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import FAQ from "./pages/FAQ/FAQ";
import Notfound from "./pages/404/Notfound";
import ThankYou from "./pages/ThankYou/ThankYou";
import VillaElanza from "./pages/VillaInner/VillaElanza";
import VillaExcelencia from "./pages/VillaInner/VillaExcelencia";
import Stellar from "./pages/VillaInner/Stellar-8";
import { enquirenow, mobileenquire, whatsappsvg, whatsappdek } from "./images";
import Download from "./components/Download/Download";

const DefaultLayout = () => {
  const { width } = useWindowDimensions();
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/bespoke-home" element={<BeSpokeHome />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/villa-estelle" element={<VillaEstelle />} />
        <Route path="/villa-elanza" element={<VillaElanza />} />
        <Route path="/villa-excelencia" element={<VillaExcelencia />} />
        <Route path="/stellar-8" element={<Stellar />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/e-brochure" element={<Download />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route exact path="/404" element={<Notfound />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
      <Footer />
      {width > 600 ? (
        <section className="enquire">
          <a href="https://wa.me/+917710026611">
            <img
              className="whatsapp_now"
              src={whatsappdek}
              alt="whatsapp img"
              loading="lazy"
            />
          </a>
          <br />
          <br />
          <NavLink to="/contact-us">
            <img
              className="enquire_now"
              src={enquirenow}
              alt="whatsapp img"
              loading="lazy"
            />
          </NavLink>
        </section>
      ) : (
        <section className="enquire">
          <img
            className="mobileenquire"
            src={mobileenquire}
            alt="backrgound img"
            loading="lazy"
          />
          <div className="flex_box">
            <a href="https://wa.me/+917710026611">
              <img
                className="whatsappmob"
                src={whatsappsvg}
                alt="whatsapp img"
                loading="lazy"
              />
            </a>
            <div className="verticle_line">|</div>
            <NavLink to="/contact-us" className="enquiretext">
              Enquire Now
            </NavLink>
          </div>
        </section>
      )}
    </>
  );
};

export default DefaultLayout;
