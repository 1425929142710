import React from "react";
import { loader } from "../../images";

const Loader = () => {
  return (
    <div className="loader_wrapper" style={{ zIndex: 9999 }}>
      <img
        src={loader}
        alt="Iraah loader img"
        loading="lazy"
        className="loader_img"
      />
    </div>
  );
};

export default Loader;
