import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Banner.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import Loader from "../Loader/Loader";

const Banner = ({ imgSrc, linkTwoText, linkTwoDisabled, headingText }) => {
  const [isLoader, setIsLoader] = useState(true);
  useEffect(() => {
    AOS.init({
      duration: 2400,
      disable: "mobile",
    });
    window.scrollTo(0, 0);
  }, [isLoader]);

  const imageLoaded = (e) => {
    if (e.target.complete && e.target.naturalHeight !== 0) {
      setIsLoader(false);
    }
  };
  return (
    <>
      <div style={{ display: isLoader ? "block" : "none" }}>
        <Loader />
      </div>
      <section className="common_banner_wrapper" data-aos="fade">
        <img
          src={imgSrc}
          alt="About us banner"
          loading="lazy"
          className="common_bannerimg"
          onLoad={imgSrc ? imageLoaded : null}
        />
        <div className="text_container">
          <div className="breadcrumb_content_wrapper">
            <Link to="/" className="breadcrumb_text">
              home
            </Link>
            {linkTwoText && (
              <Link
                to="/"
                className={`breadcrumb_text ${
                  linkTwoDisabled ? "disabled" : ""
                }`}
              >
                &nbsp;|&nbsp;{linkTwoText}
              </Link>
            )}
          </div>
          <h1 className="banner_heading">{headingText}</h1>
        </div>
      </section>
    </>
  );
};

export default Banner;
