import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DefaultLayout from "./DefaultLayout";
import "./common.scss";
// Import Swiper styles
import "swiper/css";
import "swiper/css/bundle";

import "./common.scss";
import { handelRightClick } from "./helpers/utils";

function App() {
  // document.addEventListener("contextmenu", handelRightClick);
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<DefaultLayout />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
