import React, { useEffect, useState } from "react";
import "./bespoke.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import http from "../../helpers/http";
import config from "../../config/config";
import Joi from "joi";
//components
import Banner from "../../components/Banner/Banner";
import {
  bespokebanner,
  ctaarrow,
  custom1back,
  step1,
  step2,
  step3,
  step4,
  step5,
} from "../../images";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { isInViewPort } from "../../helpers/utils";

const BeSpokeHome = () => {
  const { width } = useWindowDimensions();

  const [values, setValues] = useState({});
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const base_url = config.api_url + "forms/bespoke";

  const schema = Joi.object({
    name: Joi.string().required(),
    email: Joi.string().required(),
    phone: Joi.string().required(),

    message: Joi.string().required(),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = await validate(values);
    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }
    console.log("form submit");
    setLoading(true);

    const { data } = await http.post(base_url, values);

    if (data) {
      // redirect
      navigate("/thank-you");
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  useEffect(() => {
    AOS.init({
      duration: 2400,
      disable: "mobile",
    });
    window.scrollTo(0, 0);
  }, []);

  const handleScroll = () => {
    const section_title_stageone = document.querySelector(
      ".section_title_stageone"
    );
    const section_title_stagetwo = document.querySelector(
      ".section_title_stagetwo"
    );
    const section_title_stagethree = document.querySelector(
      ".section_title_stagethree"
    );
    const section_title_stagefour = document.querySelector(
      ".section_title_stagefour"
    );
    const section_title_stagefive = document.querySelector(
      ".section_title_stagefive"
    );
    const section_title_form = document.querySelector(".section_title_form");
    isInViewPort(
      section_title_stageone,
      ".section_title_stageone",
      ".custom_sec1 .section_title_horizantal_line"
    );

    isInViewPort(
      section_title_stagetwo,
      ".section_title_stagetwo",
      ".custom_sec2 .section_title_horizantal_line"
    );
    isInViewPort(
      section_title_stagethree,
      ".section_title_stagethree",
      ".custom_sec3 .section_title_horizantal_line"
    );
    isInViewPort(
      section_title_stagefour,
      ".section_title_stagefour",
      ".custom_sec4 .section_title_horizantal_line"
    );
    isInViewPort(
      section_title_stagefive,
      ".section_title_stagefive",
      ".custom_sec5 .section_title_horizantal_line"
    );
    isInViewPort(
      section_title_form,
      ".section_title_form",
      ".custom_sec5 .section_title_horizantal_line"
    );
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <>
      <Banner
        imgSrc={bespokebanner}
        linkTwoText="Bespoke Homes"
        linkTwoDisabled
        headingText="Bespoke Homes"
      />
      <section className="custom_sec1" data-aos="fade">
        <div className="my_container">
          <p className="description" data-aos="fade-up" data-aos-delay="500">
            As specialist developers, we listen to your needs, design it to your
            specifications and built it to your dreams!
          </p>
          <div className="row flex_box">
            <div className="col-lg-3">
              <div className="text_container">
                <div className="title_wrapper">
                  <h3 className="section_title section_title_stageone">
                    <span className="letter">S</span>
                    <span className="letter">t</span>
                    <span className="letter">a</span>
                    <span className="letter">g</span>
                    <span className="letter">e</span>&nbsp;&nbsp;
                    <span className="letter">0</span>
                    <span className="letter">1</span>
                  </h3>
                  <div className="section_title_horizantal_line_wrapper">
                    <span className="section_title_horizantal_line"></span>
                  </div>
                </div>
                <p
                  className="subheading"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  Our Expertise, Your Assurance
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="text_container">
                <p className="desc2" data-aos="fade-up" data-aos-delay="500">
                  Discover Iraah over a call or meeting to help us better
                  understand your requirements, priorities and lifestyle. This
                  discussion includes our company history, past and current
                  builds as well a wide spectrum of queries from both ends. We
                  then invite you to see and immerse within the location through
                  our eyes while offering insights into various architectural
                  design styles to decide what suits you the best where you are
                  assured of: Iraah’s design approach that accentuates unique
                  natural elements like a naturally flowing river, a stunning
                  mountain view and exceptional exterior and interior details
                  like materials, fittings, furniture, lighting and safety
                  measures. We usually show potential homeowners 2-3 villas with
                  different styles to get a sense of their preferences as well
                  as a sense of the quality we deliver.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-5 for_back"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <div className="imageanimate">
                <img
                  src={step1}
                  width="100%"
                  height="100%"
                  alt="iraah img"
                  loading="lazy"
                  className="house_img"
                />
                <img
                  src={custom1back}
                  width="100%"
                  height="100%"
                  alt="iraah img"
                  loading="lazy"
                  className="house_img_back"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="custom_sec2" data-aos="fade-up">
        <div className="my_container">
          <div className="row flex_box">
            {width <= 991 ? (
              <div className="col-lg-3">
                <div className="text_container">
                  <div className="title_wrapper">
                    <h3 className="section_title section_title_stagetwo">
                      <span className="letter">S</span>
                      <span className="letter">t</span>
                      <span className="letter">a</span>
                      <span className="letter">g</span>
                      <span className="letter">e</span>&nbsp;&nbsp;
                      <span className="letter">0</span>
                      <span className="letter">2</span>
                    </h3>
                    <div className="section_title_horizantal_line_wrapper">
                      <span className="section_title_horizantal_line"></span>
                    </div>
                  </div>
                  <p
                    className="subheading"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  >
                    Transparency in Business
                  </p>
                </div>
              </div>
            ) : null}
            {width > 991 ? (
              <div className="col-md-6 col-lg-5 for_back">
                <div className="imageanimate">
                  <img
                    src={step2}
                    width="100%"
                    height="100%"
                    alt="iraah img"
                    loading="lazy"
                    className="house_img"
                  />
                  <img
                    src={custom1back}
                    width="100%"
                    height="100%"
                    alt="iraah img"
                    loading="lazy"
                    className="house_img_back"
                  />
                </div>
              </div>
            ) : null}
            {width > 991 ? (
              <div className="col-lg-3">
                <div className="text_container">
                  <div className="title_wrapper">
                    <h3 className="section_title section_title_stagetwo">
                      <span className="letter">S</span>
                      <span className="letter">t</span>
                      <span className="letter">a</span>
                      <span className="letter">g</span>
                      <span className="letter">e</span>&nbsp;&nbsp;
                      <span className="letter">0</span>
                      <span className="letter">2</span>
                    </h3>
                    <div className="section_title_horizantal_line_wrapper">
                      <span className="section_title_horizantal_line"></span>
                    </div>
                  </div>
                  <p
                    className="subheading"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  >
                    Transparency in Business
                  </p>
                </div>
              </div>
            ) : null}
            <div className="col-md-6 col-lg-4">
              <div className="text_container">
                <p className="desc2" data-aos="fade-up" data-aos-delay="500">
                  Once we have understood your budget and requirements, we
                  prepare and share a comprehensive breakdown of commercials
                  based on your design preferences and our fixed expenses which
                  are spread in instalments. However, the commercials can only
                  be locked if you already own the land because some parcels of
                  land need more work than others, and this may affect the
                  commercials. If you already own the land, we are pleased to
                  proceed ahead. But if not, we show you land according to our
                  budget as well as unwavering support through the entire land
                  acquisition process from legal assistance in reviewing titles
                  and paperwork to purchase and registration, we have you
                  covered. Our team's local knowledge will be a source of
                  comfort and certainty for you throughout.
                </p>
              </div>
            </div>
            {width <= 991 ? (
              <div className="col-md-6 col-lg-5 for_back">
                <div className="imageanimate">
                  <img
                    src={step2}
                    width="100%"
                    height="100%"
                    alt="iraah img"
                    loading="lazy"
                    className="house_img"
                  />
                  <img
                    src={custom1back}
                    width="100%"
                    height="100%"
                    alt="iraah img"
                    loading="lazy"
                    className="house_img_back"
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </section>

      <section className="custom_sec3" data-aos="fade-up">
        <div className="my_container">
          <div className="row flex_box">
            <div className="col-lg-3">
              <div className="text_container">
                <div className="title_wrapper">
                  <h3 className="section_title section_title_stagethree">
                    <span className="letter">S</span>
                    <span className="letter">t</span>
                    <span className="letter">a</span>
                    <span className="letter">g</span>
                    <span className="letter">e</span>&nbsp;&nbsp;
                    <span className="letter">0</span>
                    <span className="letter">3</span>
                  </h3>
                  <div className="section_title_horizantal_line_wrapper">
                    <span className="section_title_horizantal_line"></span>
                  </div>
                </div>
                <p
                  className="subheading"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  selection of land acquisition & legal due diligence
                </p>
              </div>
            </div>
            {width > 991 ? (
              <div className="col-md-6 col-lg-5 for_back">
                <div className="imageanimate">
                  <img
                    src={step3}
                    width="100%"
                    height="100%"
                    alt="iraah img"
                    loading="lazy"
                    className="house_img"
                  />
                </div>
              </div>
            ) : null}
            <div className="col-md-6 col-lg-4">
              <div className="text_container">
                <p className="desc2" data-aos="fade-up" data-aos-delay="500">
                  As seasoned developers in the area, we devote some of our time
                  to locating land with the greatest value and future
                  possibilities. We schedule a visit to the possible venues
                  after discussing square footage details and imagery of what we
                  have in mind. We only show you plots that have been reviewed
                  and shortlisted because they meet all of your requirements.
                  When we talk about land, we factor metrics like localities,
                  view, diviners estimate underground and rainwater capacity,
                  testing soil for construction and agriculture and checking the
                  width and classification of access roads which have a direct
                  bearing on permissions, etc. We enable the purchase in your
                  name and assist you at every level of the acquisition
                  procedure once all of your requirements have been met.
                </p>
              </div>
            </div>
            {width <= 991 ? (
              <div className="col-md-6 col-lg-5 for_back">
                <div className="imageanimate">
                  <img
                    src={step3}
                    width="100%"
                    height="100%"
                    alt="iraah img"
                    loading="lazy"
                    className="house_img"
                  />
                  <img
                    src={custom1back}
                    width="100%"
                    height="100%"
                    alt="iraah img"
                    loading="lazy"
                    className="house_img_back"
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </section>

      <section className="custom_sec4" data-aos="fade-up">
        <div className="my_container">
          <div className="row flex_box">
            <div className="col-lg-3">
              <div className="text_container">
                <div className="title_wrapper">
                  <h3 className="section_title section_title_stagefour">
                    <span className="letter">S</span>
                    <span className="letter">t</span>
                    <span className="letter">a</span>
                    <span className="letter">g</span>
                    <span className="letter">e</span>&nbsp;&nbsp;
                    <span className="letter">0</span>
                    <span className="letter">4</span>
                  </h3>
                  <div className="section_title_horizantal_line_wrapper">
                    <span className="section_title_horizantal_line"></span>
                  </div>
                </div>
                <p
                  className="subheading"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  design summary and presentation
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="text_container">
                <p className="desc2" data-aos="fade-up" data-aos-delay="500">
                  After we arrive at a clear idea of what you are seeking, the
                  more confident we are in our ability to provide a home that is
                  prominent to your lifestyle, and taste, exceeding your
                  expectations. Further, various considerations like family
                  size, age of every member and their hobbies are taken into
                  account to help us design specifically for adults, young
                  adults and children. The design brief and presentation take
                  into account your current needs as well as those that you may
                  have in the future. We suggest a design brief using
                  renderings, floor plans, and 3D modelling. What appears to you
                  to be a seamless, natural flow in the layout has been
                  painstakingly developed so that you can enjoy particular
                  experiences. This in-depth knowledge of you and the future
                  we're co-creating assures that your experience will be
                  enhanced – one in which you'll feel secure and never lose the
                  enthusiasm you felt during your first meeting.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-5 for_back">
              <div className="imageanimate">
                <img
                  src={step4}
                  width="100%"
                  height="100%"
                  alt="iraah img"
                  loading="lazy"
                  className="house_img"
                />
                <img
                  src={custom1back}
                  width="100%"
                  height="100%"
                  alt="iraah img"
                  loading="lazy"
                  className="house_img_back"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="custom_sec5" data-aos="fade-up">
        <div className="my_container">
          <div className="row flex_box">
            {width <= 991 ? (
              <div className="col-lg-3">
                <div className="text_container">
                  <div className="title_wrapper">
                    <h3 className="section_title section_title_stagefive">
                      <span className="letter">S</span>
                      <span className="letter">t</span>
                      <span className="letter">a</span>
                      <span className="letter">g</span>
                      <span className="letter">e</span>&nbsp;&nbsp;
                      <span className="letter">0</span>
                      <span className="letter">5</span>
                    </h3>
                    <div className="section_title_horizantal_line_wrapper">
                      <span className="section_title_horizantal_line"></span>
                    </div>
                  </div>
                  <p
                    className="subheading"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  >
                    finished & furnished
                  </p>
                </div>
              </div>
            ) : null}
            {width > 991 ? (
              <div className="col-md-6 col-lg-5 for_back">
                <div className="imageanimate">
                  <img
                    src={step5}
                    width="100%"
                    height="100%"
                    alt="iraah img"
                    loading="lazy"
                    className="house_img"
                  />
                  <img
                    src={custom1back}
                    width="100%"
                    height="100%"
                    alt="iraah img"
                    loading="lazy"
                    className="house_img_back"
                  />
                </div>
              </div>
            ) : null}
            {width > 991 ? (
              <div className="col-lg-3">
                <div className="text_container">
                  <div className="title_wrapper">
                    <h3 className="section_title section_title_stagefive">
                      <span className="letter">S</span>
                      <span className="letter">t</span>
                      <span className="letter">a</span>
                      <span className="letter">g</span>
                      <span className="letter">e</span>&nbsp;&nbsp;
                      <span className="letter">0</span>
                      <span className="letter">5</span>
                    </h3>
                    <div className="section_title_horizantal_line_wrapper">
                      <span className="section_title_horizantal_line"></span>
                    </div>
                  </div>
                  <p
                    className="subheading"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  >
                    finished & furnished
                  </p>
                </div>
              </div>
            ) : null}
            <div className="col-md-6 col-lg-4">
              <div className="text_container">
                <p className="desc2" data-aos="fade-up" data-aos-delay="500">
                  Our agreement is signed, and your dreams on paper take shape,
                  now that the essential parts are in place. We start doing what
                  we do best by teaming with India's leading architects and
                  interior designers. We plan site inspections during
                  construction so you could monitor our work and be confident in
                  our dedication to delivery, quality control, and transparency.
                  Upon completion of each predetermined construction phase, we
                  share site videos, mood boards, showing interior design
                  details, lighting plans, the pool design & furniture and will
                  always execute the design only with your approval. After
                  interpreting everything supplied in our brief, your bespoke
                  home will be delivered and when you're ready to move in, we'll
                  be there to help you make the transition as smooth as
                  possible.
                </p>
              </div>
            </div>
            {width <= 991 ? (
              <div className="col-md-6 col-lg-5 for_back">
                <div className="imageanimate">
                  <img
                    src={step5}
                    width="100%"
                    height="100%"
                    alt="iraah img"
                    loading="lazy"
                    className="house_img"
                  />
                  <img
                    src={custom1back}
                    width="100%"
                    height="100%"
                    alt="iraah img"
                    loading="lazy"
                    className="house_img_back"
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </section>

      <section className="custom_sec6" data-aos="fade-up">
        <div className="my_container">
          <div className="form_box">
            <div className="text_container">
              <div className="title_wrapper">
                <h3 className="section_title section_title_form">
                  <span className="letter">G</span>
                  <span className="letter">e</span>
                  <span className="letter">t</span>&nbsp;&nbsp;
                  <span className="letter">I</span>
                  <span className="letter">n</span>&nbsp;&nbsp;
                  <span className="letter">T</span>
                  <span className="letter">o</span>
                  <span className="letter">u</span>
                  <span className="letter">c</span>
                  <span className="letter">h</span>
                </h3>
                <div className="section_title_horizantal_line_wrapper">
                  <span className="section_title_horizantal_line"></span>
                </div>
              </div>
              <p className="subheading" data-aos="fade-up" data-aos-delay="500">
                We pride ourselves on the
              </p>
            </div>
            <div className="form_container">
              <form className="form-group" onSubmit={handleSubmit}>
                {width > 767 ? (
                  <div className="contact_email">
                    <div className="material-textfield contactus">
                      <input
                        placeholder=" "
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                      />
                      <label>Name*</label>
                      <div className="error">{formError.name}</div>
                    </div>
                    <div className="material-textfield emailid">
                      <input
                        type="tel"
                        placeholder=" "
                        name="phone"
                        value={values.phone}
                        onChange={handleChange}
                      />
                      <label>Phone Number*</label>
                      <div className="error">{formError.phone}</div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="material-textfield">
                      <input
                        placeholder=" "
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                      />
                      <label>Name*</label>
                      <div className="error">{formError.name}</div>
                    </div>
                    <div className="material-textfield">
                      <input
                        type="tel"
                        placeholder=" "
                        name="phone"
                        value={values.phone}
                        onChange={handleChange}
                      />
                      <label>Phone Number*</label>
                      <div className="error">{formError.phone}</div>
                    </div>
                  </>
                )}
                {width > 767 ? (
                  <div className="contact_email">
                    <div className="material-textfield emailid">
                      <input
                        placeholder=" "
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                      <label>E-mail ID*</label>
                      <div className="error">{formError.email}</div>
                    </div>
                    <div className="material-textfield contactus">
                      <input
                        placeholder=" "
                        type="text"
                        name="message"
                        value={values.message}
                        onChange={handleChange}
                      />
                      <label>Message*</label>
                      <div className="error">{formError.message}</div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="material-textfield">
                      <input
                        placeholder=" "
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                      <label>E-mail ID*</label>
                      <div className="error">{formError.email}</div>
                    </div>
                    <div className="material-textfield">
                      <input
                        placeholder=" "
                        type="text"
                        name="message"
                        value={values.message}
                        onChange={handleChange}
                      />
                      <label>Message*</label>
                      <div className="error">{formError.message}</div>
                    </div>
                  </>
                )}
                <div className="cta_wrapper">
                  <button type="submit" className="common_btn">
                    {loading ? "Sending.." : "Send"}
                    <img
                      src={ctaarrow}
                      alt="cta arrow"
                      className="cta_arrow"
                      loading="lazy"
                    />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BeSpokeHome;
