import React, { useEffect } from "react";
import Accordion from "./Accordion";
import "./faq.scss";
import AOS from "aos";
import "aos/dist/aos.css";

const FAQ = () => {
  useEffect(() => {
    AOS.init({
      duration: 2400,
      disable: "mobile",
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="faq_sec1" data-aos="fade-up">
        <div className="my_container">
          <div className="text_container">
            <div className="breadcrumb">
              <a href="/">Home | </a> Frequently Asked Questions
            </div>
            <div className="heading">Frequently Asked Questions</div>
          </div>
          <Accordion />
        </div>
      </section>
    </>
  );
};

export default FAQ;
