import {
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  gallery5,
  gallery6,
  MasterLayoutPlan1,
  AerialView2,
  FrontElevation3,
  FrontElevationNV4,
  SwimmingPool5,
  StreetView6,
  StreetViewNV7,
  RearSideElevation9,
  LawnView10,
  Terrace12,
  Living20,
  Living18,
  Living19,
  Bathroom13,
  Bedroom17,
  Bathroom15,
  masterbedroom21,
  Bathroom14,
  masterbedroom22,
} from "../images";
export const locationGalleries = [
  {
    location: "Alibaug",
    gallery: [
      {
        img: gallery1,
        title: "Hiltop villa",
      },
      {
        img: gallery2,
        title: "Demo Title",
      },
      {
        img: gallery3,
        title: "Demo Title",
      },
      {
        img: gallery4,
        title: "Demo Title",
      },
      {
        img: gallery5,
        title: "Demo Title",
      },
      {
        img: gallery6,
        title: "Demo Title",
      },
    ],
  },
  {
    location: "Lonavala",
    gallery: [
      {
        img: MasterLayoutPlan1,
        title: "Hiltop villa",
      },
      {
        img: AerialView2,
        title: "Demo Title",
      },
      {
        img: FrontElevation3,
        title: "Demo Title",
      },
      {
        img: FrontElevationNV4,
        title: "Demo Title",
      },
      {
        img: SwimmingPool5,
        title: "Demo Title",
      },
      {
        img: StreetView6,
        title: "Demo Title",
      },
      {
        img: StreetViewNV7,
        title: "Demo Title",
      },
      {
        img: RearSideElevation9,
        title: "Demo Title",
      },
      {
        img: LawnView10,
        title: "Demo Title",
      },
      {
        img: Terrace12,
        title: "Demo Title",
      },
      {
        img: Living20,
        title: "Demo Title",
      },
      {
        img: Living18,
        title: "Demo Title",
      },
      {
        img: Living19,
        title: "Demo Title",
      },
      {
        img: Bathroom13,
        title: "Demo Title",
      },
      {
        img: Bedroom17,
        title: "Demo Title",
      },
      {
        img: Bathroom15,
        title: "Demo Title",
      },
      {
        img: masterbedroom21,
        title: "Demo Title",
      },
      {
        img: Bathroom14,
        title: "Demo Title",
      },
      {
        img: masterbedroom22,
        title: "Demo Title",
      },
    ],
  },
];
