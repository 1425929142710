import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import http from "../../helpers/http";
import config from "../../config/config";
import Joi from "joi";
//swiper imports
import { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
//components
import Banner from "../../components/Banner/Banner";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { animateEle, isInViewPort } from "../../helpers/utils";
import { Helmet } from "react-helmet";

//images
import {
  eclipsebrown,
  excelenciaamenity1,
  excelenciaamenity2,
  excelenciaamenity3,
  excelenciaamenity4,
  bbqpit,
  brownLeftArrow,
  brownRightArrow,
  ctaarrow,
  elanzapool,
  floorDownArrow,
  teahouse,
  excelenciagallery1,
  whiteLeftArrow,
  whiteRightArrow,
  excelenciabanner,
  excelenciagallery2,
  excelenciagallery4,
  excelenciagallery5,
  excelenciagallery6,
  excelenciagallery7,
  excelenciagallery8,
  excelenciagallery9,
  excelenciagallery10,
  excelenciagallery12,
  villa03floor,
  villa03ground,
  villa03first,
  villa03basement,
} from "../../images";
import "./villainner.scss";

const amenityBreakpoints = {
  0: {
    slidesPerView: 1,
  },
  540: {
    slidesPerView: 1.4,
  },
  768: {
    slidesPerView: 2,
  },
  992: {
    slidesPerView: 2.5,
  },
  1025: {
    slidesPerView: 3.2,
  },
};

const floorPlanData = [
  {
    id: 1,
    tabName: "Ground Floor",
    tabDesc: `Download floor plans <a style="text-decoration: underline;" className="download_link" href =${villa03floor} download > here </a>`,
    tabImg: villa03ground,
    tabPoints: [
      "State Of The Art Foyer",
      "Expansive Living Room",
      "Beautiful Dining Area",
      "Fully Equipped Kitchen",
      "1 Expansive Bedroom",
      "2 Bathroom",
      "1 Master Bedroom",
      "A Private Outdoor Deck Outside Rooms",
      "Dry Kitchen",
      "Partial Covered Deck Facing The Stream And Mountains",
      "Semi Covered Gazebo",
      "Plunge Pool & Deck",
      "Pool Loungers",
      "Sunk Seating & Bar",
      "Entry Courtyard",
      "Cosy Cabana",
      "Home Theatre",
      "Games & Entertainment Room",
      "Personal Elevator",
      "Semi Covered Walkway",
      "Staff Room And Bathroom",
      "Powder Room",
      "Utility Area",
      "Mandir",
      "Stairway",
    ],
  },
  // {
  //   id: 2,
  //   tabName: "Below Ground",
  //   tabDesc: `Download floor plans <a style="text-decoration: underline;" className="download_link" href =${villa03floor} download > here </a>`,
  //   tabImg: "https://via.placeholder.com/730x408/234567/fff",
  //   tabPoints: ["Changing Room", "Walkway To Change Room"],
  // },
  {
    id: 3,
    tabName: "First Floor",
    tabDesc: `Download floor plans <a style="text-decoration: underline;" className="download_link" href =${villa03floor} download > here </a>`,
    tabImg: villa03first,
    tabPoints: [
      "1 Bedroom",
      "Bathrooms",
      "1 Master Bedroom",
      "Master Bedroom Step Out",
      "Bedrooms With Open To Sky Shower",
      "Wardrobe",
      "Study Room With A Personal Garden",
      "Personal Gym",
      "Exquisite Walkway",
      "Sky Lounge With Jacuzzi And Barbeque Pit",
      "Service Terrace",
    ],
  },
];

const amenitiesArr = [
  {
    img: excelenciaamenity1,
    name: "Relaxing Spa Room",
  },
  {
    img: excelenciaamenity2,
    name: "Pool With Sunken Loungers",
  },
  {
    img: excelenciaamenity3,
    name: "Sky Lounge With Jacuzzi",
  },
  {
    img: excelenciaamenity4,
    name: "Water Stream",
  },
  // {
  //   img: amenity2,
  //   name: "Private Garden Pergola For Romantic Dining",
  // },
  // {
  //   img: amenity3,
  //   name: "Picturesque Walkway",
  // },
  // {
  //   img: amenity1,
  //   name: "Expansive Living Room With A Private Outdoor Deck",
  // },
  // {
  //   img: amenity2,
  //   name: "Indoor Bar",
  // },
  // {
  //   img: amenity3,
  //   name: "Powder Room",
  // },
  // {
  //   img: amenity1,
  //   name: "Personal Elevator",
  // },
  // {
  //   img: amenity2,
  //   name: "4 Luxurious & Spacious Ensuite Bedrooms",
  // },
  // {
  //   img: amenity3,
  //   name: "Sophisticated Huge Master Suite With A Veranda",
  // },
  // {
  //   img: amenity1,
  //   name: "Open To Sky Shower",
  // },
  // {
  //   img: amenity2,
  //   name: "Home Office With A Personal Garden",
  // },
  // {
  //   img: amenity3,
  //   name: "Sky Lounge With Jacuzzi And Barbeque Pit",
  // },
  // {
  //   img: amenity1,
  //   name: "Vastu Compliant",
  // },
];

const galleryArr = [
  {
    img: excelenciagallery1,
    title: "Hiltop villa",
  },
  {
    img: excelenciagallery2,
    title: "Demo Title",
  },
  {
    img: excelenciagallery4,
  },
  {
    img: excelenciagallery5,
  },
  {
    img: excelenciagallery6,
  },
  {
    img: excelenciagallery7,
  },
  {
    img: excelenciagallery8,
  },
  {
    img: excelenciagallery9,
  },
  {
    img: excelenciagallery10,
  },
  {
    img: excelenciagallery12,
  },
];

const VillaExcelencia = () => {
  const { width } = useWindowDimensions();
  const [floorTabIndex, setFloorTabIndex] = useState(0);

  const [values, setValues] = useState({ project_name: "Villa Excelencia" });
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);

  const scrollToForm = () => {
    const id = "villa_inner_sec9";
    const yOffset = -250;
    const element = document.getElementById(id);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  const navigate = useNavigate();

  const base_url = config.api_url + "forms/project";

  const schema = Joi.object({
    name: Joi.string().required(),
    email: Joi.string().required(),
    phone: Joi.string().required(),

    message: Joi.string().required(),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = await validate(values);
    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }
    console.log("form submit");
    setLoading(true);
    // values.project_name = project_name;
    const { data } = await http.post(base_url, values);

    if (data) {
      // redirect
      navigate("/thank-you");
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  //logical functions
  const handleFloorTabChange = (e) => {
    const activeTabId = e.target.selectedOptions[0].dataset.floortabindexid;
    console.log("activeTabId", activeTabId);
    setFloorTabIndex(Number(activeTabId));
  };

  const floorTabList = floorPlanData.map(({ tabName }, i) => (
    <span
      className={`floor_tab ${floorTabIndex === i ? "active" : ""}`}
      onClick={() => setFloorTabIndex(i)}
      key={i}
    >
      {tabName}
    </span>
  ));

  const floorPointsList = floorPlanData.map(({ tabPoints }, i) => (
    <div
      className={`floor_points_flex ${floorTabIndex === i ? "active" : ""}`}
      key={i}
    >
      {tabPoints.map((tabPoint, i) => (
        <span className="floor_point" key={i}>
          {tabPoint}
        </span>
      ))}
    </div>
  ));

  const floorName = floorPlanData.map(({ tabName, tabDesc }, i) => (
    <>
      <p
        className="section_heading_desc"
        data-aos="fade-up"
        data-aos-delay="500"
      >
        {floorTabIndex === i ? `${tabName}` : ""}
      </p>
      <p
        className="section_desc"
        data-aos="fade-up"
        data-aos-delay="500"
        dangerouslySetInnerHTML={{
          __html: `${floorTabIndex === i ? `${tabDesc}` : ""}`,
        }}
      ></p>
    </>
  ));

  const floorImage = floorPlanData.map(({ tabImg }, i) => (
    <>
      <img
        key={i}
        src={tabImg}
        alt="Floor plan"
        className={`floor_plan_img ${floorTabIndex === i ? "active" : ""}`}
        loading="lazy"
        width={729}
        height={407}
      />
    </>
  ));

  const amenitiesList = amenitiesArr.map((item, i) => (
    <>
      <SwiperSlide>
        <div className="amenity_slide">
          <figure className="amenity_img_wrapper">
            <img
              src={item.img}
              alt=""
              loading="lazy"
              width={502}
              height={634}
            />
          </figure>
          <span className="amenity_name">{item.name}</span>
        </div>
      </SwiperSlide>
    </>
  ));

  const mbFloorTabList = floorPlanData.map(({ tabName }, i) => (
    <option value={tabName} key={i} data-floortabindexid={i}>
      {tabName}
    </option>
  ));

  const galleryList = galleryArr.map((item, i) => (
    <SwiperSlide>
      <div className="image_wrapper">
        <div className="imageanimate">
          {width > 600 ? (
            <img
              className="gallery_img"
              src={item.img}
              width="100%"
              alt="gallery img"
              loading="lazy"
            />
          ) : (
            <img
              className="gallery_img"
              src={item.img}
              width="100%"
              alt="gallery img"
              loading="lazy"
            />
          )}
        </div>
        <div className="title_text">{item.title}</div>
      </div>
    </SwiperSlide>
  ));

  useEffect(() => {
    AOS.init({
      duration: 2400,
      disable: "mobile",
    });
    // window.scrollTo(0, 0);
    animateEle(
      ".section_title_villa",
      ".villa_inner_sec2 .section_title_horizantal_line"
    );
  }, []);

  const handleScroll = () => {
    const section_title_whythis = document.querySelector(
      ".section_title_whythis"
    );
    const section_title_amenities = document.querySelector(
      ".section_title_amenities"
    );
    const section_title_floorplan = document.querySelector(
      ".section_title_floorplan"
    );
    const section_title_gallery = document.querySelector(
      ".section_title_gallery"
    );
    const section_title_projteam = document.querySelector(
      ".section_title_projteam"
    );
    const section_title_map = document.querySelector(".section_title_map");
    const section_title_form = document.querySelector(".section_title_form");
    isInViewPort(
      section_title_whythis,
      ".section_title_whythis",
      ".villa_inner_sec3 .section_title_horizantal_line"
    );
    isInViewPort(
      section_title_amenities,
      ".section_title_amenities",
      ".villa_inner_sec4 .section_title_horizantal_line"
    );
    isInViewPort(
      section_title_floorplan,
      ".section_title_floorplan",
      ".villa_inner_sec5 .section_title_horizantal_line"
    );
    isInViewPort(
      section_title_gallery,
      ".section_title_gallery",
      ".villa_inner_sec6 .section_title_horizantal_line"
    );
    isInViewPort(
      section_title_projteam,
      ".section_title_projteam",
      ".villa_inner_sec7 .section_title_horizantal_line"
    );
    isInViewPort(
      section_title_map,
      ".section_title_map",
      ".villa_inner_sec8 .section_title_horizantal_line"
    );
    isInViewPort(
      section_title_form,
      ".section_title_form",
      ".villa_inner_sec9 .section_title_horizantal_line"
    );
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <>
      <main>
        <Banner
          imgSrc={excelenciabanner}
          linkTwoText="Villa Excelencia"
          linkTwoDisabled
          headingText="Villa Excelencia"
        />

        <div>
          <Helmet>
            <title>Luxury villas in alibaug for sale - Book Now !</title>
            <meta name="description" content="Discover luxury villas in alibaug for sale for your next vacation or getaway. Experience premium amenities and stunning scenery Book now !" />
            <meta name="keywords" content="luxury villas in alibaug" />
            <link rel="canonical" href="https://iraahlifespaces.com/villa-excelencia" />

            <script type='application/ld+json'>
            {JSON.stringify({
              "@context": "http://schema.org",
              "@type": "Product",
              "name": "Villa Excelencia",
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "5.0",
                "ratingCount": "4",
                "reviewCount": "4"            
              }            
            })}
            </script>

            <h1 style="position: absolute; margin-left: -2000px">luxury villas in alibaug for sale</h1>
          </Helmet>
        </div>

        <section className="villa_inner_sec2" data-aos="fade-up">
          <div className="my_container">
            <div className="row">
              <div className="col-lg-7">
                <div className="title_wrapper">
                  <h3 className="section_title section_title_villa">
                    <span className="letter">V</span>
                    <span className="letter">i</span>
                    <span className="letter">l</span>
                    <span className="letter">l</span>
                    <span className="letter">a</span>&nbsp;&nbsp;
                    <span className="letter">e</span>
                    <span className="letter">x</span>
                    <span className="letter">c</span>
                    <span className="letter">e</span>
                    <span className="letter">l</span>
                    <span className="letter">e</span>
                    <span className="letter">n</span>
                    <span className="letter">c</span>
                    <span className="letter">i</span>
                    <span className="letter">a</span>
                  </h3>
                  <div className="section_title_horizantal_line_wrapper">
                    <span className="section_title_horizantal_line"></span>
                  </div>
                </div>
                <p
                  className="section_heading_desc"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  An Undiscovered Landmark, An Avenue Ready For Move-In!
                </p>
              </div>
              <div className="col-lg-5">
                <p
                  className="section_desc"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  Excelencia, In Spanish Resonates To Excelling Property. At
                  Villa Excelencia, we introduce you to a life you've aspired
                  for, and world-class interior designs you've always yearned
                  for with the gentle nurture of nature. Built to compliment the
                  impeccable beauty where the true magic lies in the
                  uninterrupted posh views of a naturally flowing river,
                  surrounded by mountains and beyond, you are surely transported
                  to a world of paradise at one of the most breathtaking
                  locations.
                </p>
                <br />
                <div
                  className="configurations_villas"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  <ul style={{ listStyleType: "none" }}>
                    <li>
                      CONFIGURATION - <span>5 BHK++</span>
                    </li>
                    <li>
                      CARPET AREA - <span>9236 sqft</span>
                    </li>
                    <li>
                      AMENITIES AREA - <span>2347sqft</span>
                    </li>
                    <li>
                      PLOT AREA - <span>24533 sqft</span>
                    </li>
                  </ul>
                </div>
                <div
                  className="cta_wrapper"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  <button className="common_btn" onClick={scrollToForm}>
                    Enquire Now
                    <img
                      src={ctaarrow}
                      alt="cta arrow"
                      className="cta_arrow"
                      loading="lazy"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="villa_inner_video" data-aos="fade-up">
          <div className="my_container">
            <img
              className="video_thumbnail"
              src={videothumbnail}
              alt="video_banner"
              loading="lazy"
              onClick={() => setToggler(!toggler)}
            />
            <img
              className="play_icon"
              src={playicon}
              alt="video_banner"
              loading="lazy"
              onClick={() => setToggler(!toggler)}
            />
          </div>
        </section> */}

        <section className="villa_inner_sec3" data-aos="fade-up">
          <div className="my_container">
            <div className="row">
              <div className="col-md-8">
                <div className="text_container">
                  <div className="title_wrapper">
                    <h3 className="section_title section_title_whythis">
                      <span className="letter">W</span>
                      <span className="letter">h</span>
                      <span className="letter">y</span>&nbsp;&nbsp;
                      <span className="letter">T</span>
                      <span className="letter">h</span>
                      <span className="letter">i</span>
                      <span className="letter">s</span>&nbsp;&nbsp;
                      <span className="letter">V</span>
                      <span className="letter">i</span>
                      <span className="letter">l</span>
                      <span className="letter">l</span>
                      <span className="letter">a</span>
                      <span className="letter">?</span>
                    </h3>
                    <div className="section_title_horizantal_line_wrapper">
                      <span className="section_title_horizantal_line"></span>
                    </div>
                  </div>
                  <p
                    className="section_heading_desc"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  >
                    Vibrant Location. Picturesque Views. Luxury Living. It All
                    Starts Here.
                  </p>
                </div>
              </div>
              <div className="col-md-4 col2">
                {/* {width >= 768 && (
                <div className="navigation_arrows_wrapper">
                  <div className="navigation_arrow navigation_left_arrow">
                    <img
                      src={whiteLeftArrow}
                      alt="Navigation arrow"
                      loading="lazy"
                      className="arrow_logo arrow_white"
                    />
                    <img
                      src={brownLeftArrow}
                      alt="Navigation arrow"
                      loading="lazy"
                      className="arrow_logo arrow_brown"
                    />
                  </div>
                  <div className="navigation_arrow navigation_right_arrow">
                    <img
                      src={whiteRightArrow}
                      alt="Navigation arrow"
                      loading="lazy"
                      className="arrow_logo arrow_white"
                    />
                    <img
                      src={brownRightArrow}
                      alt="Navigation arrow"
                      loading="lazy"
                      className="arrow_logo arrow_brown"
                    />
                  </div>
                </div>
              )} */}
              </div>
            </div>
            <div className="usps_swiper_wrapper">
              <Swiper
                modules={[Navigation]}
                slidesPerView={3}
                spaceBetween={20}
                navigation={{
                  prevEl: ".villa_inner_sec3 .navigation_left_arrow",
                  nextEl: ".villa_inner_sec3 .navigation_right_arrow",
                }}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: true,
                }}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                  768: {
                    slidesPerView: 1.5,
                    spaceBetween: 0,
                  },
                  992: {
                    slidesPerView: 2,
                  },
                  1194: {
                    slidesPerView: 3,
                  },
                }}
              >
                <SwiperSlide>
                  <div className="usp_box">
                    <img
                      src={bbqpit}
                      alt=""
                      loading="lazy"
                      className="usplogo"
                    />
                    <div
                      className="usp_name"
                      data-aos="fade-up"
                      data-aos-delay="500"
                    >
                      Sky Lounge With A Jacuzzi And Barbeque Pit
                    </div>
                    <div
                      className="usp_desc section_desc"
                      data-aos="fade-up"
                      data-aos-delay="500"
                    >
                      Be at the top of the world and soak your tensions away in
                      the inviting jacuzzi or step out for a barbeque and
                      embrace your life beneath the stars.
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="usp_box">
                    <img
                      src={teahouse}
                      alt=""
                      loading="lazy"
                      className="usplogo"
                    />
                    <div
                      className="usp_name"
                      data-aos="fade-up"
                      data-aos-delay="500"
                    >
                      Zesty Tea House{" "}
                    </div>
                    <div
                      className="usp_desc section_desc"
                      data-aos="fade-up"
                      data-aos-delay="500"
                    >
                      Tea House by the river is your getaway place to relax and
                      enjoy the breeze of the wind and the soothing sensation of
                      nature.
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="usp_box">
                    <img
                      src={elanzapool}
                      alt=""
                      loading="lazy"
                      className="usplogo"
                    />
                    <div
                      className="usp_name"
                      data-aos="fade-up"
                      data-aos-delay="500"
                    >
                      Pool With Sunken Loungers
                    </div>
                    <div
                      className="usp_desc section_desc"
                      data-aos="fade-up"
                      data-aos-delay="500"
                    >
                      Swim away your worries with a splash in the sparkling blue
                      water or sit by the cosy and inviting sunken loungers when
                      you want to spend some time alone or with your loved ones.
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
              {width <= 767 && (
                <div className="navigation_arrows_wrapper">
                  <div className="navigation_arrow navigation_left_arrow">
                    <img
                      src={whiteLeftArrow}
                      alt="Navigation arrow"
                      loading="lazy"
                      className="arrow_logo arrow_white"
                    />
                    <img
                      src={brownLeftArrow}
                      alt="Navigation arrow"
                      loading="lazy"
                      className="arrow_logo arrow_brown"
                    />
                  </div>
                  <div className="navigation_arrow navigation_right_arrow">
                    <img
                      src={whiteRightArrow}
                      alt="Navigation arrow"
                      loading="lazy"
                      className="arrow_logo arrow_white"
                    />
                    <img
                      src={brownRightArrow}
                      alt="Navigation arrow"
                      loading="lazy"
                      className="arrow_logo arrow_brown"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>

        <section className="villa_inner_sec4" data-aos="fade-up">
          <div className="my_container">
            <div className="row">
              <div className="col-md-8">
                <div className="text_container">
                  <div className="title_wrapper">
                    <h3 className="section_title section_title_amenities">
                      <span className="letter">A</span>
                      <span className="letter">m</span>
                      <span className="letter">e</span>
                      <span className="letter">n</span>
                      <span className="letter">i</span>
                      <span className="letter">t</span>
                      <span className="letter">i</span>
                      <span className="letter">e</span>
                      <span className="letter">s</span>
                    </h3>
                    <div className="section_title_horizantal_line_wrapper">
                      <span className="section_title_horizantal_line"></span>
                    </div>
                  </div>
                  <p
                    className="section_heading_desc"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  >
                    Stellar Amenities To Live The Extraordinary
                  </p>
                </div>
              </div>
              <div className="col-md-4 col2">
                {width >= 768 && (
                  <div className="navigation_arrows_wrapper">
                    <div className="navigation_arrow navigation_left_arrow">
                      <img
                        src={whiteLeftArrow}
                        alt="Navigation arrow"
                        loading="lazy"
                        className="arrow_logo arrow_white"
                      />
                      <img
                        src={brownLeftArrow}
                        alt="Navigation arrow"
                        loading="lazy"
                        className="arrow_logo arrow_brown"
                      />
                    </div>
                    <div className="navigation_arrow navigation_right_arrow">
                      <img
                        src={whiteRightArrow}
                        alt="Navigation arrow"
                        loading="lazy"
                        className="arrow_logo arrow_white"
                      />
                      <img
                        src={brownRightArrow}
                        alt="Navigation arrow"
                        loading="lazy"
                        className="arrow_logo arrow_brown"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className="amenity_swiper_wrapper"
            data-aos="fade-up"
            data-aos-delay="500"
          >
            <Swiper
              modules={[Navigation, Autoplay]}
              spaceBetween={20}
              navigation={{
                prevEl: ".villa_inner_sec4 .navigation_left_arrow",
                nextEl: ".villa_inner_sec4 .navigation_right_arrow",
              }}
              // loop={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: true,
              }}
              breakpoints={amenityBreakpoints}
            >
              {amenitiesList}
            </Swiper>
            {width <= 767 && (
              <div className="navigation_arrows_wrapper">
                <div className="navigation_arrow navigation_left_arrow">
                  <img
                    src={whiteLeftArrow}
                    alt="Navigation arrow"
                    loading="lazy"
                    className="arrow_logo arrow_white"
                  />
                  <img
                    src={brownLeftArrow}
                    alt="Navigation arrow"
                    loading="lazy"
                    className="arrow_logo arrow_brown"
                  />
                </div>
                <div className="navigation_arrow navigation_right_arrow">
                  <img
                    src={whiteRightArrow}
                    alt="Navigation arrow"
                    loading="lazy"
                    className="arrow_logo arrow_white"
                  />
                  <img
                    src={brownRightArrow}
                    alt="Navigation arrow"
                    loading="lazy"
                    className="arrow_logo arrow_brown"
                  />
                </div>
              </div>
            )}
          </div>
        </section>

        <section className="villa_inner_sec5" data-aos="fade-up">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 col1">
                <div className="text_container">
                  <div className="title_wrapper">
                    <h3 className="section_title section_title_floorplan">
                      <span className="letter">F</span>
                      <span className="letter">l</span>
                      <span className="letter">o</span>
                      <span className="letter">o</span>
                      <span className="letter">r</span>&nbsp;&nbsp;
                      <span className="letter">P</span>
                      <span className="letter">l</span>
                      <span className="letter">a</span>
                      <span className="letter">n</span>
                    </h3>
                    <div className="section_title_horizantal_line_wrapper">
                      <span className="section_title_horizantal_line"></span>
                    </div>
                  </div>
                  {floorName}
                </div>
                {floorImage}
              </div>
              <div className="col-lg-6 col2">
                {width >= 540 ? (
                  <div className="floor_tabs_flex">{floorTabList}</div>
                ) : (
                  <div className="mb_floor_tab_wrapper">
                    <select
                      onChange={handleFloorTabChange}
                      className="mb_tab_dropdown"
                    >
                      {mbFloorTabList}
                    </select>
                    <img
                      src={floorDownArrow}
                      alt="floor Down Arrow"
                      className="floorDownArrow"
                      loading="lazy"
                    />
                  </div>
                )}
                {floorPointsList}
              </div>
            </div>
          </div>
        </section>

        <section className="villa_inner_sec6" data-aos="fade-up">
          <div className="my_container">
            <div className="text_container">
              <div className="title_wrapper">
                <h3 className="section_title section_title_gallery">
                  <span className="letter">V</span>
                  <span className="letter">i</span>
                  <span className="letter">e</span>
                  <span className="letter">w</span>&nbsp;&nbsp;
                  <span className="letter">G</span>
                  <span className="letter">a</span>
                  <span className="letter">l</span>
                  <span className="letter">l</span>
                  <span className="letter">e</span>
                  <span className="letter">r</span>
                  <span className="letter">y</span>
                </h3>
                <div className="section_title_horizantal_line_wrapper">
                  <span className="section_title_horizantal_line"></span>
                </div>
              </div>
              <p className="subheading" data-aos="fade-up" data-aos-delay="500">
                Villa Gallery
              </p>
            </div>
            <div
              className="villa_gallery_swiper"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <Swiper
                modules={[Pagination, Navigation, Autoplay]}
                navigation={{
                  prevEl: `.villa_gallery_swiper .navigation_left_arrow`,
                  nextEl: `.villa_gallery_swiper .navigation_right_arrow`,
                }}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: true,
                }}
                className="mySwiper"
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 50,
                  },
                  600: {
                    slidesPerView: 1.4,
                    spaceBetween: 50,
                  },
                  1194: {
                    slidesPerView: 1.7,
                    spaceBetween: 75,
                  },
                  1600: {
                    slidesPerView: 1.7,
                    spaceBetween: 95,
                  },
                }}
              >
                {galleryList}
              </Swiper>
              <div className="navigation_arrows_wrapper">
                <div className="navigation_arrow navigation_left_arrow">
                  <img
                    src={whiteLeftArrow}
                    alt="Navigation arrow"
                    loading="lazy"
                    className="arrow_logo arrow_white"
                  />
                  <img
                    src={brownLeftArrow}
                    alt="Navigation arrow"
                    loading="lazy"
                    className="arrow_logo arrow_brown"
                  />
                </div>
                <div className="navigation_arrow navigation_right_arrow">
                  <img
                    src={whiteRightArrow}
                    alt="Navigation arrow"
                    loading="lazy"
                    className="arrow_logo arrow_white"
                  />
                  <img
                    src={brownRightArrow}
                    alt="Navigation arrow"
                    loading="lazy"
                    className="arrow_logo arrow_brown"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="villa_inner_sec7" data-aos="fade-up">
          <div className="my_container">
            <div className="row flex_box">
              <div className="col-md-6 col-lg-3">
                <div className="text_container">
                  <div className="title_wrapper">
                    <h3 className="section_title section_title_projteam">
                      <span className="letter">O</span>
                      <span className="letter">u</span>
                      <span className="letter">r</span>&nbsp;&nbsp;
                      <span className="letter">P</span>
                      <span className="letter">r</span>
                      <span className="letter">o</span>
                      <span className="letter">j</span>
                      <span className="letter">e</span>
                      <span className="letter">c</span>
                      <span className="letter">t</span>&nbsp;&nbsp;
                      <span className="letter">T</span>
                      <span className="letter">e</span>
                      <span className="letter">a</span>
                      <span className="letter">m</span>
                    </h3>
                    <div className="section_title_horizantal_line_wrapper">
                      <span className="section_title_horizantal_line"></span>
                    </div>
                  </div>
                  <p
                    className="subheading"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  >
                    Meet The Team
                  </p>
                </div>
                {width > 767 ? (
                  <div className="navigation_arrows_wrapper team_navigation">
                    <div className="navigation_arrow navigation_left_arrow">
                      <img
                        src={whiteLeftArrow}
                        alt="Navigation arrow"
                        loading="lazy"
                        className="arrow_logo arrow_white"
                      />
                      <img
                        src={brownLeftArrow}
                        alt="Navigation arrow"
                        loading="lazy"
                        className="arrow_logo arrow_brown"
                      />
                    </div>
                    <div className="navigation_arrow navigation_right_arrow">
                      <img
                        src={whiteRightArrow}
                        alt="Navigation arrow"
                        loading="lazy"
                        className="arrow_logo arrow_white"
                      />
                      <img
                        src={brownRightArrow}
                        alt="Navigation arrow"
                        loading="lazy"
                        className="arrow_logo arrow_brown"
                      />
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="col-md-6 col-lg-9">
                <div className="team_members_swiper_wrapper">
                  <Swiper
                    modules={[Navigation, Autoplay]}
                    autoplay={{
                      delay: 3000,
                      disableOnInteraction: false,
                    }}
                    loop={true}
                    navigation={{
                      prevEl: `.team_navigation .navigation_left_arrow`,
                      nextEl: `.team_navigation .navigation_right_arrow`,
                    }}
                    slidesPerView={3}
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                        spaceBetween: 15,
                      },
                      600: {
                        slidesPerView: 1.2,
                        spaceBetween: 20,
                      },
                      992: {
                        slidesPerView: 2.7,
                      },
                      1700: {
                        slidesPerView: 2.4,
                      },
                    }}
                  >
                    <SwiperSlide>
                      <div className="member_box">
                        <div className="member_text_content">
                          <span
                            className="member_name"
                            data-aos="fade-up"
                            data-aos-delay="250"
                          >
                            Sapna Thanwala
                          </span>
                          <span
                            className="member_desg"
                            data-aos="fade-up"
                            data-aos-delay="250"
                          >
                            Design Architect
                          </span>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="member_box">
                        <div className="member_text_content">
                          <span
                            className="member_name"
                            data-aos="fade-up"
                            data-aos-delay="250"
                          >
                            Shonali Mahajan
                          </span>
                          <span
                            className="member_desg"
                            data-aos="fade-up"
                            data-aos-delay="250"
                          >
                            Interior Design Architect
                          </span>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="member_box">
                        <div className="member_text_content">
                          <span
                            className="member_name"
                            data-aos="fade-up"
                            data-aos-delay="250"
                          >
                            Mr A Y Retiwalla
                          </span>
                          <span
                            className="member_desg"
                            data-aos="fade-up"
                            data-aos-delay="250"
                          >
                            Landscape Consultant
                          </span>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="member_box">
                        <div className="member_text_content">
                          <span
                            className="member_name"
                            data-aos="fade-up"
                            data-aos-delay="250"
                          >
                            Mr Mahesh Chauhan
                          </span>
                          <span
                            className="member_desg"
                            data-aos="fade-up"
                            data-aos-delay="250"
                          >
                            RCC Consultant
                          </span>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="member_box">
                        <div className="member_text_content">
                          <span
                            className="member_name"
                            data-aos="fade-up"
                            data-aos-delay="250"
                          >
                            Nehal Thakkar
                          </span>
                          <span
                            className="member_desg"
                            data-aos="fade-up"
                            data-aos-delay="250"
                          >
                            MEP Consultant
                          </span>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="member_box">
                        <div className="member_text_content">
                          <span
                            className="member_name"
                            data-aos="fade-up"
                            data-aos-delay="250"
                          >
                            Mr Ravi Marriwala
                          </span>
                          <span
                            className="member_desg"
                            data-aos="fade-up"
                            data-aos-delay="250"
                          >
                            Water Consultant
                          </span>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="member_box">
                        <div className="member_text_content">
                          <span
                            className="member_name"
                            data-aos="fade-up"
                            data-aos-delay="250"
                          >
                            Mr Rajiv M Jhaveri
                          </span>
                          <span
                            className="member_desg"
                            data-aos="fade-up"
                            data-aos-delay="250"
                          >
                            Vastu Consultant
                          </span>
                        </div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
                {width < 767 ? (
                  <div className="navigation_arrows_wrapper team_navigation">
                    <div className="navigation_arrow navigation_left_arrow">
                      <img
                        src={whiteLeftArrow}
                        alt="Navigation arrow"
                        loading="lazy"
                        className="arrow_logo arrow_white"
                      />
                      <img
                        src={brownLeftArrow}
                        alt="Navigation arrow"
                        loading="lazy"
                        className="arrow_logo arrow_brown"
                      />
                    </div>
                    <div className="navigation_arrow navigation_right_arrow">
                      <img
                        src={whiteRightArrow}
                        alt="Navigation arrow"
                        loading="lazy"
                        className="arrow_logo arrow_white"
                      />
                      <img
                        src={brownRightArrow}
                        alt="Navigation arrow"
                        loading="lazy"
                        className="arrow_logo arrow_brown"
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </section>

        <section className="villa_inner_sec8">
          <div className="my_container">
            <div className="row flex_box">
              <div className="col-lg-3">
                <div className="text_container">
                  <div className="title_wrapper">
                    <h3 className="section_title section_title_map">
                      <span className="letter">O</span>
                      <span className="letter">u</span>
                      <span className="letter">r</span>&nbsp;&nbsp;
                      <span className="letter">L</span>
                      <span className="letter">o</span>
                      <span className="letter">c</span>
                      <span className="letter">a</span>
                      <span className="letter">t</span>
                      <span className="letter">i</span>
                      <span className="letter">o</span>
                      <span className="letter">n</span>
                    </h3>
                    <div className="section_title_horizantal_line_wrapper">
                      <span className="section_title_horizantal_line"></span>
                    </div>
                  </div>
                  <p
                    className="subheading"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  >
                    Address
                  </p>
                  <img
                    width="132"
                    src={eclipsebrown}
                    alt="eclipse img"
                    className="map_eclipse"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  />
                  <p
                    className="section_desc"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  >
                    Gat No. 132, Village Satirje, Taluka Alibag, District Raigad
                  </p>
                </div>
              </div>
              <div className="col-lg-9">
                <div
                  className="inner_map_wrapper"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  {width > 767 ? (
                    <iframe
                      title="google map frame for desktop"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30225.84434481351!2d72.8654712055728!3d18.743236531737715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be877f52f4f739d%3A0xa7b80d9c2cd5da52!2sSatirje%2C%20Maharashtra%20402201!5e0!3m2!1sen!2sin!4v1658405989986!5m2!1sen!2sin"
                      width="100%"
                      height="597"
                      style={{ border: "0", margin: "0", padding: "0" }}
                      loading="lazy"
                    ></iframe>
                  ) : (
                    <iframe
                      title="youtube frame for mobile"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30225.84434481351!2d72.8654712055728!3d18.743236531737715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be877f52f4f739d%3A0xa7b80d9c2cd5da52!2sSatirje%2C%20Maharashtra%20402201!5e0!3m2!1sen!2sin!4v1658405989986!5m2!1sen!2sin"
                      width="100%"
                      height="400"
                      style={{ border: "0", margin: "0", padding: "0" }}
                      loading="lazy"
                    ></iframe>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="villa_inner_sec9"
          id="villa_inner_sec9"
          data-aos="fade-up"
        >
          <div className="my_container">
            <div className="form_box">
              <div className="text_container">
                <div className="title_wrapper">
                  <h3 className="section_title section_title_form">
                    <span className="letter">G</span>
                    <span className="letter">e</span>
                    <span className="letter">t</span>&nbsp;&nbsp;
                    <span className="letter">I</span>
                    <span className="letter">n</span>&nbsp;&nbsp;
                    <span className="letter">T</span>
                    <span className="letter">o</span>
                    <span className="letter">u</span>
                    <span className="letter">c</span>
                    <span className="letter">h</span>
                  </h3>
                  <div className="section_title_horizantal_line_wrapper">
                    <span className="section_title_horizantal_line"></span>
                  </div>
                </div>
                <p
                  className="subheading"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  We pride ourselves on the
                </p>
              </div>
              <div className="form_container">
                <form className="form-group" onSubmit={handleSubmit}>
                  {width > 767 ? (
                    <div className="contact_email">
                      <div className="material-textfield contactus">
                        <input
                          placeholder=" "
                          type="text"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                        />
                        <label>Name*</label>
                        <div className="error">{formError.name}</div>
                      </div>
                      <div className="material-textfield emailid">
                        <input
                          type="tel"
                          placeholder=" "
                          name="phone"
                          value={values.phone}
                          onChange={handleChange}
                        />
                        <label>Phone Number*</label>
                        <div className="error">{formError.phone}</div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="material-textfield">
                        <input
                          placeholder=" "
                          type="text"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                        />
                        <label>Name*</label>
                        <div className="error">{formError.name}</div>
                      </div>
                      <div className="material-textfield">
                        <input
                          type="tel"
                          placeholder=" "
                          name="phone"
                          value={values.phone}
                          onChange={handleChange}
                        />
                        <label>Phone Number*</label>
                        <div className="error">{formError.phone}</div>
                      </div>
                    </>
                  )}
                  {width > 767 ? (
                    <div className="contact_email">
                      <div className="material-textfield emailid">
                        <input
                          placeholder=" "
                          type="email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                        />
                        <label>E-mail ID*</label>
                        <div className="error">{formError.email}</div>
                      </div>
                      <div className="material-textfield contactus">
                        <input
                          placeholder=" "
                          type="text"
                          name="message"
                          value={values.message}
                          onChange={handleChange}
                        />
                        <label>Message*</label>
                        <div className="error">{formError.message}</div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="material-textfield">
                        <input
                          placeholder=" "
                          type="email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                        />
                        <label>E-mail ID*</label>
                        <div className="error">{formError.email}</div>
                      </div>
                      <div className="material-textfield">
                        <input
                          placeholder=" "
                          type="text"
                          name="message"
                          value={values.message}
                          onChange={handleChange}
                        />
                        <label>Message*</label>
                        <div className="error">{formError.message}</div>
                      </div>
                    </>
                  )}
                  <div className="cta_wrapper">
                    <button type="submit" className="common_btn">
                      {loading ? "Sending.." : "Send"}
                      <img
                        src={ctaarrow}
                        alt="cta arrow"
                        className="cta_arrow"
                        loading="lazy"
                      />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* <FsLightbox
        toggler={toggler}
        sources={["https://www.youtube.com/embed/HHjSdy9l7Kc"]}
      /> */}
    </>
  );
};

export default VillaExcelencia;
