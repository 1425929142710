export const isInViewPort = (element, eleStr, horizantalLineStr) => {
  // Get the bounding client rectangle position in the viewport
  var bounding = element.getBoundingClientRect();
  // Checking part. Here the code checks if it's *fully* visible
  // Edit this part if you just want a partial visibility
  if (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth) &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight)
  ) {
    const el = document.querySelectorAll(`${eleStr} .letter`);
    const horizantalLineEl = document.querySelector(horizantalLineStr);
    const letterArr = Array.from(el);
    const animName = "letterAnim";
    let animDelay = 0;

    letterArr.forEach((spanEl) => {
      spanEl.style.animation = `${animName} 1s linear ${animDelay}s forwards`;
      animDelay += 0.1;
    });
    horizantalLineEl.style.animation = `afterAnim 1s linear ${animDelay}s forwards`;
  }
};
export const animateEle = (eleStr, horizantalLineStr) => {
  const el = document.querySelectorAll(`${eleStr} .letter`);
  const horizantalLineEl = document.querySelector(horizantalLineStr);
  const letterArr = Array.from(el);
  const animName = "letterAnim";
  let animDelay = 0;

  letterArr.forEach((spanEl) => {
    spanEl.style.animation = `${animName} 1s linear ${animDelay}s forwards`;
    animDelay += 0.2;
  });
  horizantalLineEl.style.animation = `afterAnim 1s linear ${animDelay}s forwards`;
};

export function handelRightClick(event) {
  event.preventDefault();
}
