import React, { useState } from "react";
import { Data } from "./Data";
import { plus, minus } from "../../images";
import "./faq.scss";

const Accordion = () => {
  const [clicked, setClicked] = useState(0);
  const toggle = (index) => {
    if (clicked === index) {
      //if clicked question is already active, then close it
      return setClicked(null);
    }

    setClicked(index);
  };

  return (
    <>
      <div className="accordionsec">
        <div className="acc_container">
          {Data.map((item, index) => {
            return (
              <>
                <div className="accoedion_box">
                  <div
                    className="acc_wrap"
                    onClick={() => toggle(index)}
                    key={index}
                  >
                    <span className="acc_span">
                      {clicked === index ? (
                        <img
                          className="minus_icon"
                          src={minus}
                          alt="minus icon img"
                          loading="lazy"
                        />
                      ) : (
                        <img
                          className="plus_icon"
                          src={plus}
                          alt="plus icon img"
                          loading="lazy"
                        />
                      )}
                    </span>
                    <h1 className="title">{item.question}</h1>
                  </div>
                  <div className="acc_dropdown">
                    {clicked === index ? (
                      <p className="acc_p">{item.answer}</p>
                    ) : null}
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Accordion;
