import React, { useEffect } from "react";

import AOS from "aos";
import "aos/dist/aos.css";
import "./Notfound.scss";
import { error } from "../../images";
import { Link } from "react-router-dom";

const Notfound = () => {
  useEffect(() => {
    AOS.init({
      duration: 2400,
      disable: "mobile",
    });
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="pagenotfoundsec" data-aos="fade">
        <div className="notfound_container">
          <div className="headp">
            <img
              className="pagenotimg1"
              src={error}
              alt="404 img"
              loading="lazy"
            />
            <h1 className="heading">Oops! That Page Can’t Be Found.</h1>
            <p>THE PAGE YOU ARE LOOKING FOR DOES NOT EXITS</p>
            <p className="mobilep">
              THE PAGE YOU ARE LOOKING FOR DOES NOT EXITS
            </p>
            <div>
              <p className="link_p">
                Please return to{" "}
                <Link className="brown_link" to="/">
                  Home page
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Notfound;
