import React, { useEffect, useState } from "react";
import "./contactus.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import "../../components/CommonCta/commoncta.scss";

//components
import Banner from "../../components/Banner/Banner";
import { contact1, contact1back, contactban, ctaarrow } from "../../images";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import http from "../../helpers/http";
import config from "../../config/config";
import Joi from "joi";
import { Helmet } from "react-helmet";


const ContactUs = (props) => {
  const { width } = useWindowDimensions();

  const [values, setValues] = useState({});
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const base_url = config.api_url + "forms/contact";

  const schema = Joi.object({
    name: Joi.string().required(),
    email: Joi.string().required(),
    phone: Joi.string().required(),
    category: Joi.string().required(),
    message: Joi.string().required(),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = await validate(values);
    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }
    console.log("form submit");
    setLoading(true);

    const { data } = await http.post(base_url, values);

    if (data) {
      // redirect
      navigate("/thank-you");
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  useEffect(() => {
    console.log(props);
    AOS.init({
      duration: 2400,
      disable: "mobile",
    });
    window.scrollTo(0, 0);
  }, [props]);

  return (
    <>
      <Banner
        imgSrc={contactban}
        linkTwoText="contact us"
        linkTwoDisabled
        headingText="contact us"
      />

    <div>
      <Helmet>
      <title>Properties for Sale in Lonavala - Your Dream Home Awaits</title>
      <meta name='description' content='Searching for properties for sale in Lonavala? Iraahlifespaces can help you find the perfect property in this charming hill station.' />
      <meta name="keywords" content="properties in lonavala" />
      <link rel="canonical" href="https://iraahlifespaces.com/contact-us" />
      <h1 style="position: absolute; margin-left: 2000px;">properties in lonavala for sale</h1>
      </Helmet>
    </div>

      <section className="contact_sec1" data-aos="fade-up">
        <div className="my_container">
          <div className="row">
            <div className="col-lg-5">
              <div className="text_container">
                <div className="heading">Locate Us</div>
                <p className="subheading">You can find us here</p>
              </div>
              <div className="text_container">
                <div className="title">iraah lifespaces</div>
                <div className="desc">
                  103 A, Aurus Chamber, Annex ‘B’, 1st Floor, S. S. Amrutwar
                  Marg, Behind Mahindra Towers,Worli, Mumbai – 400 013
                </div>
              </div>
              <div className="text_container">
                <div className="title">PHONE</div>
                <div className="desc phone_no_desc">
                  <a href="tel: +912240211106"> +91 22 40211106</a>
                </div>
                <div className="desc phone_no_desc">
                  <a href="tel: +912240211107"> +91 22 40211107</a>
                </div>
                <div className="desc phone_no_desc">
                  <a href="tel: +919967773810"> +91 99677 73810</a>
                </div>
              </div>
              <div className="text_container">
                <div className="title">Mail Id</div>
                <div className="desc">
                  <a href="mailto:info@iraahlifespaces.com">
                    info@iraahlifespaces.com
                  </a>
                </div>
              </div>
              <div className="text_container">
                <div className="title">Website</div>
                <div className="desc">
                  <a href="www.iraahlifespaces.com">www.iraahlifespaces.com</a>
                </div>
              </div>
              {/* <div className="text_container">
                <div className="title">HOURS</div>
                <div className="desc">8-4pm Monday - Friday</div>
              </div> */}
            </div>
            <div className="col-lg-7 map_wrapper">
              {width > 767 ? (
                <iframe
                  title="google map frame for desktop"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3772.353918238568!2d72.8206024!3d19.0041201!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7ce93efdc11fb%3A0x80227787e5b65881!2sAurus%20Chambers%2C%20Shiv%20Sai%20Nivas%2C%20Shivram%20Seth%20Amritwar%20Marg%2C%20Lower%20Parel%20West%2C%20Lower%20Parel%2C%20Mumbai%2C%20Maharashtra%20400013!5e0!3m2!1sen!2sin!4v1652872801486!5m2!1sen!2sin"
                  width="100%"
                  height="607"
                  style={{ border: "0", margin: "0", padding: "0" }}
                  loading="lazy"
                ></iframe>
              ) : (
                <iframe
                  title="google map frame for mobile"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3772.353918238568!2d72.8206024!3d19.0041201!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7ce93efdc11fb%3A0x80227787e5b65881!2sAurus%20Chambers%2C%20Shiv%20Sai%20Nivas%2C%20Shivram%20Seth%20Amritwar%20Marg%2C%20Lower%20Parel%20West%2C%20Lower%20Parel%2C%20Mumbai%2C%20Maharashtra%20400013!5e0!3m2!1sen!2sin!4v1652872801486!5m2!1sen!2sin"
                  width="100%"
                  height="502"
                  style={{ border: "0", margin: "0", padding: "0" }}
                  loading="lazy"
                ></iframe>
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="contact_sec2" data-aos="fade-up">
        <div className="my_container">
          <div className="row">
            <div className="col-lg-5 image_box">
              <div className="imageanimate">
                <img
                  className="image_left"
                  src={contact1}
                  alt="contact img"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="col-lg-7 content_box">
              {width > 992 ? (
                <img
                  className="image_right"
                  src={contact1back}
                  alt="right img"
                  loading="lazy"
                />
              ) : null}
              <div className="text_container">
                <div className="heading">Get In Touch</div>
                <p className="subheading">To know more about us</p>
              </div>
              <div className="form_container">
                <form className="form-group" onSubmit={handleSubmit}>
                  {width > 767 ? (
                    <>
                      <div className="material-textfield">
                        <input
                          placeholder=" "
                          type="text"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                        />
                        <label>Name*</label>
                        <div className="error">{formError.name}</div>
                      </div>
                      <div className="material-textfield emailid">
                        <input
                          type="tel"
                          placeholder=" "
                          name="phone"
                          value={values.phone}
                          onChange={handleChange}
                        />
                        <label>Phone Number*</label>
                        <div className="error">{formError.phone}</div>
                      </div>
                      <div className="material-textfield emailid">
                        <input
                          placeholder=" "
                          type="email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                        />
                        <label>E-mail ID*</label>
                        <div className="error">{formError.email}</div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="material-textfield">
                        <input
                          placeholder=" "
                          type="text"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                        />
                        <label>Name*</label>
                        <div className="error">{formError.name}</div>
                      </div>
                      <div className="material-textfield">
                        <input
                          type="tel"
                          placeholder=" "
                          name="phone"
                          value={values.phone}
                          onChange={handleChange}
                        />
                        <label>Phone Number*</label>
                        <div className="error">{formError.phone}</div>
                      </div>
                    </>
                  )}
                  {width > 767 ? (
                    <>
                      <div className="material-textfield">
                        <select
                          className="select"
                          name="category"
                          value={values.category}
                          onChange={handleChange}
                        >
                          <option defaultValue value>
                            Select Category*
                          </option>
                          <option value="Careers" className="demo1 active">
                            Careers
                          </option>
                          <option value="General">General</option>
                        </select>
                        <div className="error">{formError.category}</div>
                      </div>
                      <div className="material-textfield">
                        <input
                          placeholder=" "
                          type="text"
                          name="message"
                          value={values.message}
                          onChange={handleChange}
                        />
                        <label>Message*</label>
                        <div className="error">{formError.message}</div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="material-textfield">
                        <input
                          placeholder=" "
                          type="email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                        />
                        <label>E-mail ID*</label>
                        <div className="error">{formError.email}</div>
                      </div>
                      <div className="material-textfield">
                        <select
                          className="select"
                          name="category"
                          value={values.category}
                          onChange={handleChange}
                        >
                          <option defaultValue value>
                            Select Category*
                          </option>
                          <option value="demo1" className="demo1 active">
                            Demo1
                          </option>
                          <option value="demo2">Demo2</option>
                          <option value="demo3">Demo3</option>
                        </select>
                        <div className="error">{formError.category}</div>
                      </div>
                      <div className="material-textfield">
                        <input
                          placeholder=" "
                          type="text"
                          name="message"
                          value={values.message}
                          onChange={handleChange}
                        />
                        <label>Message*</label>
                        <div className="error">{formError.message}</div>
                      </div>
                    </>
                  )}
                  <div className="cta_wrapper">
                    <button type="submit" className="common_btn">
                      {loading ? "Sending.." : "Send"}
                      <img
                        src={ctaarrow}
                        alt="cta arrow"
                        className="cta_arrow"
                        loading="lazy"
                      />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
