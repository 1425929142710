import React, { Component } from "react";
import { IraahBrochure } from "../../images";

class Download extends Component {
  render() {
    return (
      <div className="App">
        <a href={IraahBrochure} target="_blank" rel="noreferrer">
          {""}
        </a>
      </div>
    );
  }
}

export default Download;
