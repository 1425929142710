import React, { useEffect, useState } from "react";
import "./footer.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { CommonCta } from "../CommonCta/CommonCta";
import {
  facebook,
  footerarrow,
  instadesktop,
  iraahlogo,
  twitter,
  uparrow,
  uparrowmob,
} from "../../images";
import { NavLink } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";

import { useNavigate } from "react-router-dom";
import http from "../../helpers/http";
import config from "../../config/config";
import Joi from "joi";

const Footer = () => {
  const { width } = useWindowDimensions();
  const [values, setValues] = useState({});
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const base_url = config.api_url + "forms/footer";

  const schema = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email"),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = await validate(values);
    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }
    console.log("form submit");
    setLoading(true);
    // values.project_name = project_name;
    const { data } = await http.post(base_url, values);

    if (data) {
      // redirect
      navigate("/thank-you");
    }
    setValues({ email: "" });
    setLoading(false);
  };

  const handleChange = (e) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const scrollHandler = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    AOS.init({
      duration: 2400,
      disable: "mobile",
    });
  }, []);
  return (
    <>
      <section className="footer_sec1" data-aos="fade">
        {width <= 600 ? (
          <img
            className="uparrow_img"
            src={uparrowmob}
            alt="iraah arrow"
            loading="lazy"
            onClick={scrollHandler}
          />
        ) : null}
        <div className="my_container">
          {width > 600 ? (
            <>
              <div className="flex_box">
                <div className="flex1">
                  <div className="text_container">
                    <div className="title">Contact us</div>
                    <div className="desc">
                      <a href="tel:+919967773810">+91 99677 73810</a>
                    </div>
                  </div>
                  <div className="text_container">
                    <div className="title">Email address</div>
                    <div className="desc">
                      <a href="mailto:info@iraahlifespaces.com">
                        info@iraahlifespaces.com
                      </a>
                    </div>
                  </div>
                  <div className="text_container">
                    <CommonCta
                      white
                      linkTo="/contact-us"
                      btnText="ENQUIRE NOW"
                    />
                  </div>
                </div>
                <div className="flex2">
                  <NavLink className="link" to="/">
                    <img
                      className="logo_img"
                      src={iraahlogo}
                      alt="iraah logo"
                      loading="lazy"
                      onClick={scrollHandler}
                    />
                  </NavLink>
                </div>
                <div className="flex3">
                  <div className="text_container">
                    <div className="title">Get in touch</div>
                    <div className="text">
                      <form onSubmit={handleSubmit}>
                        <input
                          type="text"
                          placeholder="Enter Your Email Address"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                        />
                        <div className="error">{formError.email}</div>

                        <button type="submit">
                          <img
                            className="arrow_right"
                            src={footerarrow}
                            alt="whatsapp img"
                            loading="lazy"
                          />
                        </button>
                      </form>
                    </div>
                    <div className="social_container">
                      {/* <a href="" rel="noreferrer" target="_blank">
                        <img
                          className="social_img"
                          src={youtube}
                          alt="iraah youtube"
                          loading="lazy"
                        />
                      </a> */}
                      <a
                        href="https://twitter.com/iraahlifespaces?s=11&t=KmdGsub_hNnGKANIW_9wCw"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img
                          className="social_img"
                          src={twitter}
                          alt="iraah youtube"
                          loading="lazy"
                        />
                      </a>
                      <a
                        href="https://www.facebook.com/profile.php?id=100080698698544"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img
                          className="social_img"
                          src={facebook}
                          alt="iraah youtube"
                          loading="lazy"
                        />
                      </a>
                      <a
                        href="https://www.instagram.com/iraahlifespaces/?igshid=YmMyMTA2M2Y="
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img
                          className="social_img insta"
                          src={instadesktop}
                          alt="iraah youtube"
                          loading="lazy"
                        />
                      </a>
                    </div>
                    <img
                      className="uparrow_img"
                      src={uparrow}
                      alt="iraah arrow"
                      loading="lazy"
                      onClick={scrollHandler}
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div className="navlinks">
                <ul>
                  <li>
                    <NavLink className="link" to="/about-us">
                      ABOUT US
                    </NavLink>                    
                  </li>
                  <li>
                    <a className="link" href="https://blog.iraahlifespaces.com/">
                      BLOGS
                    </a>
                  </li>
                  {/* <li>
                <NavLink className="link" to="/projects">
                  PROJECTS
                </NavLink>
              </li> */}
                  <li>
                    <NavLink className="link" to="/bespoke-home">
                      BESPOKE HOME
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink className="link" to="/faq">
                      FAQ
                    </NavLink>
                  </li> */}
                  {/* <li>
                    <NavLink className="link" to="/virtual-tour">
                      VIRTUAL TOUR
                    </NavLink>
                  </li> */}
                  {/* <li>
                    <a
                      className="link"
                      href={IraahBrochure}
                      target="_blank"
                      rel="noreferrer"
                    >
                      E-BROCHURE
                    </a>
                  </li> */}
                </ul>
              </div>
              <hr />
              <div className="copyright_container">
                <div className="box1">
                  <p>@2022 All rights reserved by Iraah.</p>
                </div>
                <div className="box2">
                  <p>
                    Crafted by{" "}
                    <a href="https://www.togglehead.in/">Togglehead</a>
                  </p>
                </div>
                <div className="box3">
                  <NavLink to="/privacy-policy" className="privacy">
                    Privacy Policy
                  </NavLink>
                  <NavLink to="/terms-and-conditions" className="terms">
                    T&C
                  </NavLink>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex2">
                <img
                  className="logo_img"
                  src={iraahlogo}
                  alt="iraah logo"
                  loading="lazy"
                  onClick={scrollHandler}
                />
              </div>
              <div className="navlinks">
                <ul>
                  <li>
                    <NavLink className="link" to="/about-us">
                      ABOUT US
                    </NavLink>
                  </li>
                  {/* <li>
            <NavLink className="link" to="/projects">
              PROJECTS
            </NavLink>
          </li> */}
                  <li>
                    <NavLink className="link" to="/bespoke-home">
                      BESPOKE HOME
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink className="link" to="/faq">
                      FAQ
                    </NavLink>
                  </li> */}
                  {/* <li>
                    <NavLink className="link" to="/virtual-tour">
                      VIRTUAL TOUR
                    </NavLink>
                  </li> */}
                  {/* <li>
                    <a
                      className="link"
                      href={IraahBrochure}
                      target="_blank"
                      rel="noreferrer"
                    >
                      E-BROCHURE
                    </a>
                  </li> */}
                </ul>
              </div>
              <div className="flex1">
                <div className="text_container">
                  <div className="title">Contact us</div>
                  <div className="desc">
                    <a href="tel:+919967773810">+91 99677 73810</a>
                  </div>
                </div>
                <div className="text_container">
                  <div className="title">Email address</div>
                  <div className="desc">
                    <a href="mailto:info@iraahlifespaces.com">
                      info@iraahlifespaces.com
                    </a>
                  </div>
                </div>
                <div className="text_container">
                  <CommonCta white linkTo="/contact-us" btnText="ENQUIRE NOW" />
                </div>
              </div>
              <div className="flex3">
                <div className="text_container">
                  <div className="title">Get in touch</div>
                  <div className="text">
                    <form onSubmit={handleSubmit}>
                      <input
                        type="text"
                        placeholder="Enter Your Email Address"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                      <div className="error">{formError.email}</div>
                      <button type="submit">
                        <img
                          className="arrow_right"
                          src={footerarrow}
                          alt="right arrow img"
                          loading="lazy"
                        />
                      </button>
                    </form>
                  </div>
                  <div className="social_container">
                    {/* <a href="" rel="noreferrer" target="_blank">
                      <img
                        className="social_img"
                        src={youtube}
                        alt="iraah youtube"
                        loading="lazy"
                      />
                    </a> */}
                    <a
                      href="https://twitter.com/iraahlifespaces?s=11&t=KmdGsub_hNnGKANIW_9wCw"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img
                        className="social_img"
                        src={twitter}
                        alt="iraah youtube"
                        loading="lazy"
                      />
                    </a>
                    <a
                      href="https://www.facebook.com/profile.php?id=100080698698544"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img
                        className="social_img"
                        src={facebook}
                        alt="iraah youtube"
                        loading="lazy"
                      />
                    </a>
                    <a
                      href="https://www.instagram.com/iraahlifespaces/?igshid=YmMyMTA2M2Y="
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img
                        className="social_img insta"
                        src={instadesktop}
                        alt="iraah youtube"
                        loading="lazy"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="copyright_container">
                <div className="box3">
                  <NavLink to="/privacy-policy" className="privacy">
                    Privacy Policy
                  </NavLink>
                  <NavLink to="/terms-and-conditions" className="terms">
                    T&C
                  </NavLink>
                </div>
                <div className="box1">
                  <p>@2022 All rights reserved by Iraah.</p>
                </div>
                <div className="box2">
                  <p>
                    Crafted by{" "}
                    <a href="https://www.togglehead.in/">Togglehead</a>
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default Footer;
