import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import http from "../../helpers/http";
import config from "../../config/config";
import Joi from "joi";
//swiper imports
import { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
//components
import Banner from "../../components/Banner/Banner";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { animateEle, isInViewPort } from "../../helpers/utils";
import { Helmet } from "react-helmet";

//images
import {
  brownLeftArrow,
  brownRightArrow,
  ctaarrow,
  floorDownArrow,
  whiteLeftArrow,
  whiteRightArrow,
  amenities1,
  amenities2,
  amenities3,
  amenities4,
  gndfloor,
  firstfloor,
  terrace,
  MasterLayoutPlan1,
  AerialView2,
  FrontElevation3,
  FrontElevationNV4,
  SwimmingPool5,
  StreetView6,
  StreetViewNV7,
  RearSideElevation9,
  LawnView10,
  Terrace12,
  Bathroom13,
  Bathroom14,
  Bathroom15,
  Bedroom17,
  Living18,
  Living19,
  Living20,
  masterbedroom21,
  masterbedroom22,
  eclipsebrown,
  stellar8,
  stellarbanner,
  plungepoollounge,
  modernexteriors,
  earthyexperience,
  picturesqueviews,
  googlemap,
} from "../../images";
import "./villainner.scss";

const amenityBreakpoints = {
  0: {
    slidesPerView: 1,
  },
  540: {
    slidesPerView: 1.4,
  },
  768: {
    slidesPerView: 2,
  },
  992: {
    slidesPerView: 2.5,
  },
  1025: {
    slidesPerView: 3.2,
  },
};

const floorPlanData = [
  {
    id: 1,
    tabName: "Ground Floor",
    tabDesc: `Download floor plans <a style="text-decoration: underline;" className="download_link" href =${stellar8} download > here </a>`,
    tabImg: gndfloor,
    tabPoints: [
      "Living /Dining",
      "Stylish contemporary kitchen",
      "Powder room",
      "Cocktail Bar Lounge",
      "Guest Bedroom",
      "Ensuite Bathroom",
      "Servants quarter",
      // "Living/Dinning",
      // "Skylit Lounge/Bar",
      // "Kitchen",
      // "Bedroom 1",
      // "Bedroom 1 Bathroom",
      // "Powder Toilet",
      // "Staircase & Passage",
      // "Servant Room + Utility",
      // "Servant Room Bathroom",
    ],
  },
  {
    id: 2,
    tabName: "First Floor",
    tabDesc: `Download floor plans <a style="text-decoration: underline;" className="download_link" href =${stellar8} download > here </a>`,
    tabImg: firstfloor,
    tabPoints: [
      "Master Bedroom with Ensuite bathroom",
      "Deck area",
      "Junior Master",
      "Guest Bedroom",
      "Family area",
      // "Master Bedroom 2",
      // "Master Bedroom 2 Bathroom",
      // "Kids / Guest Bedroom",
      // "Kids / Guest Bathroom",
      // "Ensuite Master Bedroom",
      // "Ensuite Master  Bathroom",
      // "Deck",
      // "Passage",
    ],
  },
  {
    id: 3,
    tabName: "Terrace Area",
    tabDesc: `Download floor plans <a style="text-decoration: underline;" className="download_link" href =${stellar8} download > here </a>`,
    tabImg: terrace,
    tabPoints: [
      "Skylit Lounge",
      "Bbq **",
      "**Optional",
      // "Open to Sky Terrace",
    ],
  },
];

const amenitiesArr = [
  {
    id: Math.random(),
    img: amenities1,
    name: "Modern & Contemporary Influences",
  },
  {
    id: Math.random(),
    img: amenities3,
    name: "Avant-Garde Kitchen",
  },
  {
    id: Math.random(),
    img: amenities2,
    name: "Plunge Pool ",
  },
  {
    id: Math.random(),
    img: amenities4,
    name: "Skylit Bar",
  },
];

const galleryArr = [
  {
    img: MasterLayoutPlan1,
    // title: "Hiltop villa",
  },
  {
    img: AerialView2,
    // title: "Demo Title",
  },
  {
    img: FrontElevation3,
  },
  {
    img: FrontElevationNV4,
  },
  {
    img: SwimmingPool5,
  },
  {
    img: StreetView6,
  },
  {
    img: StreetViewNV7,
  },
  // {
  //   img: MainGate8,
  // },
  {
    img: RearSideElevation9,
  },
  {
    img: LawnView10,
  },
  // {
  //   img: MainGateSide11,
  // },
  {
    img: Terrace12,
  },
  {
    img: Living20,
  },
  {
    img: Living18,
  },
  {
    img: Living19,
  },
  {
    img: Bathroom13,
  },
  {
    img: Bedroom17,
  },
  {
    img: Bathroom15,
  },
  {
    img: masterbedroom21,
  },
  {
    img: Bathroom14,
  },
  {
    img: masterbedroom22,
  },
  // {
  //   img: Bedroom16,
  // },
  // {
  //   img: Living20,
  // },
];

const Stellar = () => {
  // const [toggler, setToggler] = useState(false);
  const { width } = useWindowDimensions();
  const [floorTabIndex, setFloorTabIndex] = useState(0);

  const [values, setValues] = useState({ project_name: "Stellar 8" });
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);

  const scrollToForm = () => {
    const id = "villa_inner_sec9";
    const yOffset = -250;
    const element = document.getElementById(id);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  const navigate = useNavigate();

  const base_url = config.api_url + "forms/project";

  const schema = Joi.object({
    name: Joi.string().required(),
    email: Joi.string().required(),
    phone: Joi.string().required(),

    message: Joi.string().required(),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = await validate(values);
    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }
    console.log("form submit");
    setLoading(true);
    // values.project_name = project_name;
    const { data } = await http.post(base_url, values);

    if (data) {
      // redirect
      navigate("/thank-you");
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  //logical functions
  const handleFloorTabChange = (e) => {
    const activeTabId = e.target.selectedOptions[0].dataset.floortabindexid;
    console.log("activeTabId", activeTabId);
    setFloorTabIndex(Number(activeTabId));
  };

  const floorTabList = floorPlanData.map(({ tabName }, i) => (
    <span
      className={`floor_tab ${floorTabIndex === i ? "active" : ""}`}
      onClick={() => setFloorTabIndex(i)}
      key={i}
    >
      {tabName}
    </span>
  ));

  const floorPointsList = floorPlanData.map(({ tabPoints }, i) => (
    <div
      className={`floor_points_flex ${floorTabIndex === i ? "active" : ""} && ${
        floorTabIndex === 2 ? "terrace" : ""
      }`}
      key={i}
    >
    {tabPoints.map((tabPoint, i) => (
        <span className="floor_point" key={i}>
          {tabPoint}
        </span>
      ))}
    </div>
  ));

  const floorName = floorPlanData.map(({ tabName, tabDesc }, i) => (
    <>
      <p
        className="section_heading_desc"
        data-aos="fade-up"
        data-aos-delay="500"
      >
        {floorTabIndex === i ? `${tabName}` : ""}
      </p>
      <p
        className="section_desc"
        data-aos="fade-up"
        data-aos-delay="500"
        dangerouslySetInnerHTML={{
          __html: `${floorTabIndex === i ? `${tabDesc}` : ""}`,
        }}
      ></p>
    </>
  ));

  const floorImage = floorPlanData.map(({ tabImg }, i) => (
    <>
      <img
        key={i}
        src={tabImg}
        alt="Floor plan"
        className={`floor_plan_img ${floorTabIndex === i ? "active" : ""}`}
        loading="lazy"
        width={729}
        height={407}
      />
    </>
  ));

  const amenitiesList = amenitiesArr.map((item, i) => (
    <>
      <SwiperSlide>
        <div>
          <div className="amenity_slide">
            <figure className="amenity_img_wrapper">
              <img
                src={item.img}
                alt=""
                loading="lazy"
                width={502}
                height={634}
              />
            </figure>
            <span className="amenity_name">{item.name}</span>
          </div>
        </div>
      </SwiperSlide>
    </>
  ));

  const mbFloorTabList = floorPlanData.map(({ tabName }, i) => (
    <option value={tabName} key={i} data-floortabindexid={i}>
      {tabName}
    </option>
  ));
 

  const galleryList = galleryArr.map((item, i) => (
    <SwiperSlide>
      <div className="image_wrapper">
        <div className="imageanimate">
          {width > 600 ? (
            <img
              className="gallery_img"
              src={item.img}
              width="100%"
              alt="gallery img"
              loading="lazy"
            />
          ) : (
            <img
              className="gallery_img"
              src={item.img}
              width="100%"
              alt="gallery img"
              loading="lazy"
            />
          )}
        </div>
        <div className="title_text">{item.title}</div>
      </div>
    </SwiperSlide>
  ));

  useEffect(() => {
    AOS.init({
      duration: 2400,
      disable: "mobile",
    });

    animateEle(
      ".section_title_villa",
      ".villa_inner_sec2 .section_title_horizantal_line"
    );
    // window.scrollTo(0, 0);
  }, []);
  const handleScroll = () => {
    const section_title_whythis = document.querySelector(
      ".section_title_whythis"
    );
    const section_title_amenities = document.querySelector(
      ".section_title_amenities"
    );
    const section_title_floorplan = document.querySelector(
      ".section_title_floorplan"
    );
    const section_title_gallery = document.querySelector(
      ".section_title_gallery"
    );
    const section_title_projteam = document.querySelector(
      ".section_title_projteam"
    );
    const section_title_map = document.querySelector(".section_title_map");
    const section_title_form = document.querySelector(".section_title_form");
    isInViewPort(
      section_title_whythis,
      ".section_title_whythis",
      ".villa_inner_sec3 .section_title_horizantal_line"
    );
    isInViewPort(
      section_title_amenities,
      ".section_title_amenities",
      ".villa_inner_sec4 .section_title_horizantal_line"
    );
    isInViewPort(
      section_title_floorplan,
      ".section_title_floorplan",
      ".villa_inner_sec5 .section_title_horizantal_line"
    );
    isInViewPort(
      section_title_gallery,
      ".section_title_gallery",
      ".villa_inner_sec6 .section_title_horizantal_line"
    );
    isInViewPort(
      section_title_projteam,
      ".section_title_projteam",
      ".villa_inner_sec7 .section_title_horizantal_line"
    );
    isInViewPort(
      section_title_map,
      ".section_title_map",
      ".villa_inner_sec8 .section_title_horizantal_line"
    );
    isInViewPort(
      section_title_form,
      ".section_title_form",
      ".villa_inner_sec9 .section_title_horizantal_line"
    );
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <main>
        <Banner
          imgSrc={stellarbanner}
          linkTwoText="Stellar 8"
          linkTwoDisabled
          headingText="Stellar 8"
        />

        <div>
          <Helmet>
            <title> Luxury villa for sale in lonavala at Prime Location</title>
            <meta name="description" content="Find your dream luxury villa for sale in lonavala. Explore our collection of exquisite properties with stunning views and luxurious amenities." />
            <meta name="keywords" content="lonavala villa for sale" />

            <script type='application/ld+json'>
            {JSON.stringify({
              "@context": "http://schema.org",
              "@type": "Product",
              "name": "Villa Excelencia",
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "5.0",
                "ratingCount": "4",
                "reviewCount": "4"            
              }            
            })}
            </script>

            <h1 style="position: absolute; margin-left: -2000px">luxury villa for sale in lonavala</h1>
          </Helmet>
        </div>

        <div>
          <Helmet>
            <title>Luxury villa for sale in lonavala at Prime Location</title>
            <meta type="Find your dream luxury villa for sale in lonavala. Explore our collection of exquisite properties with stunning views and luxurious amenities." />
            <meta name="keywords" content="lonavala villa for sale" />

            <script type='application/ld+json'>
            {JSON.stringify({
              "@context": "http://schema.org",
              "@type": "Product",
              "name": "Stellar 8",
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "5.0",
                "ratingCount": "4",
                "reviewCount": "4"
              }
            })}
            </script>

            <h1 style="position: absolute; margin-left: -2000px">luxury villa for sale in lonavala</h1>
          </Helmet>
        </div>

        <section className="villa_inner_sec2" data-aos="fade-up">
          <div className="my_container">
            <div className="row">
              <div className="col-lg-7">
                <div className="title_wrapper">
                  <h3 className="section_title section_title_villa">
                    <span className="letter">S</span>
                    <span className="letter">t</span>
                    <span className="letter">e</span>
                    <span className="letter">l</span>
                    <span className="letter">l</span>
                    <span className="letter">a</span>
                    <span className="letter">r</span>&nbsp;&nbsp;
                    <span className="letter">8</span>
                  </h3>
                  <div className="section_title_horizantal_line_wrapper">
                    <span className="section_title_horizantal_line"></span>
                  </div>
                </div>
                <p
                  className="section_heading_desc"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  Wake up amidst Nature, Discover a New Way of Life!
                </p>
              </div>
              <div className="col-lg-5">
                <p
                  className="section_desc"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  Stellar 8 is a reflection of outstanding architecture,
                  unparalleled design and the pinnacle of high quality
                  craftsmanship. Travel through scenic road curves through rich
                  greens to find the Stellar 8 villas, an alluring retreat
                  nestled away in the undulating flora of Lonavala with
                  breathtaking sunset & mountain view. Encompassing a collection
                  of 8 luxurious 4 BHK villas, artfully laid among manicured
                  gardens that boast a sustainable and futuristic living.
                </p>
                <br />
                <div
                  className="configurations_villas"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  <ul style={{ listStyleType: "none" }}>
                    <li>
                      CONFIGURATION - <span>4 BHK</span>
                    </li>
                    <li>
                      USABLE CARPET AREA - <span>2389 sqft</span>
                    </li>
                    <li>
                      AMENITIES AREA -{" "}
                      <span>
                        Terrace lounge , Swimming Pool & Landscape Garden
                      </span>
                    </li>
                    <li>
                      PLOT AREA - <span>4000 - 6000 sqft</span>
                    </li>
                  </ul>
                </div>

                <div
                  className="cta_wrapper"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  <button className="common_btn" onClick={scrollToForm}>
                    Enquire Now
                    <img
                      src={ctaarrow}
                      alt="cta arrow"
                      className="cta_arrow"
                      loading="lazy"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="villa_inner_video" data-aos="fade-up">
          <div className="my_container">
            <img
              className="video_thumbnail"
              src={videothumbnail}
              alt="video_banner"
              loading="lazy"
              onClick={() => setToggler(!toggler)}
            />
            <img
              className="play_icon"
              src={playicon}
              alt="video_banner"
              loading="lazy"
              onClick={() => setToggler(!toggler)}
            />
          </div>
        </section> */}

        <section className="villa_inner_sec3" data-aos="fade-up">
          <div className="my_container">
            <div className="row">
              <div className="col-md-8">
                <div className="text_container">
                  <div className="title_wrapper">
                    <h3 className="section_title section_title_whythis">
                      <span className="letter">W</span>
                      <span className="letter">h</span>
                      <span className="letter">y</span>&nbsp;&nbsp;
                      <span className="letter">T</span>
                      <span className="letter">h</span>
                      <span className="letter">i</span>
                      <span className="letter">s</span>&nbsp;&nbsp;
                      <span className="letter">V</span>
                      <span className="letter">i</span>
                      <span className="letter">l</span>
                      <span className="letter">l</span>
                      <span className="letter">a</span>
                      <span className="letter">?</span>
                    </h3>
                    <div className="section_title_horizantal_line_wrapper">
                      <span className="section_title_horizantal_line"></span>
                    </div>
                  </div>
                  <p
                    className="section_heading_desc"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  >
                    Untouched Luxury Amidst Serenity
                  </p>
                </div>
              </div>
              <div className="col-md-4 col2">
                {width >= 768 && (
                  <div className="navigation_arrows_wrapper">
                    <div className="navigation_arrow navigation_left_arrow">
                      <img
                        src={whiteLeftArrow}
                        alt="Navigation arrow"
                        loading="lazy"
                        className="arrow_logo arrow_white"
                      />
                      <img
                        src={brownLeftArrow}
                        alt="Navigation arrow"
                        loading="lazy"
                        className="arrow_logo arrow_brown"
                      />
                    </div>
                    <div className="navigation_arrow navigation_right_arrow">
                      <img
                        src={whiteRightArrow}
                        alt="Navigation arrow"
                        loading="lazy"
                        className="arrow_logo arrow_white"
                      />
                      <img
                        src={brownRightArrow}
                        alt="Navigation arrow"
                        loading="lazy"
                        className="arrow_logo arrow_brown"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="usps_swiper_wrapper">
              <Swiper
                modules={[Navigation, Autoplay]}
                slidesPerView={3}
                spaceBetween={20}
                navigation={{
                  prevEl: ".villa_inner_sec3 .navigation_left_arrow",
                  nextEl: ".villa_inner_sec3 .navigation_right_arrow",
                }}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: true,
                }}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                  768: {
                    slidesPerView: 1.5,
                    spaceBetween: 0,
                  },
                  992: {
                    slidesPerView: 2,
                  },
                  1194: {
                    slidesPerView: 3,
                  },
                }}
              >
                <SwiperSlide>
                  <div className="usp_box">
                    <img
                      src={plungepoollounge}
                      alt=""
                      loading="lazy"
                      className="usplogo"
                    />
                    <div
                      className="usp_name"
                      data-aos="fade-up"
                      data-aos-delay="500"
                    >
                      Plunge pool with lounge seating
                    </div>
                    <div
                      className="usp_desc section_desc"
                      data-aos="fade-up"
                      data-aos-delay="500"
                    >
                      A private open-air plunge pool with lounge seating, deck
                      chairs and vertical gardens that make it a central space
                      to spend blissful time.
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="usp_box">
                    <img
                      src={modernexteriors}
                      alt=""
                      loading="lazy"
                      className="usplogo"
                    />
                    <div
                      className="usp_name"
                      data-aos="fade-up"
                      data-aos-delay="500"
                    >
                      Modern Exteriors
                    </div>
                    <div
                      className="usp_desc section_desc"
                      data-aos="fade-up"
                      data-aos-delay="500"
                    >
                      Beautifully crafted homes with an effortless indoor
                      outdoor flow. Seamless connections with nature.
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="usp_box">
                    <img
                      src={earthyexperience}
                      alt=""
                      loading="lazy"
                      className="usplogo"
                    />
                    <div
                      className="usp_name"
                      data-aos="fade-up"
                      data-aos-delay="500"
                    >
                      Earthy Experience
                    </div>
                    <div
                      className="usp_desc section_desc"
                      data-aos="fade-up"
                      data-aos-delay="500"
                    >
                      Rustic charms and earthy experiences with zen-designed
                      bathrooms.
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="usp_box">
                    <img
                      src={picturesqueviews}
                      alt=""
                      loading="lazy"
                      className="usplogo"
                    />
                    <div
                      className="usp_name"
                      data-aos="fade-up"
                      data-aos-delay="500"
                    >
                      Picturesque Views
                    </div>
                    <div
                      className="usp_desc section_desc"
                      data-aos="fade-up"
                      data-aos-delay="500"
                    >
                      Picturesque views with manicured landscaping and
                      well-crafted gardens.
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
              {width <= 767 && (
                <div className="navigation_arrows_wrapper">
                  <div className="navigation_arrow navigation_left_arrow">
                    <img
                      src={whiteLeftArrow}
                      alt="Navigation arrow"
                      loading="lazy"
                      className="arrow_logo arrow_white"
                    />
                    <img
                      src={brownLeftArrow}
                      alt="Navigation arrow"
                      loading="lazy"
                      className="arrow_logo arrow_brown"
                    />
                  </div>
                  <div className="navigation_arrow navigation_right_arrow">
                    <img
                      src={whiteRightArrow}
                      alt="Navigation arrow"
                      loading="lazy"
                      className="arrow_logo arrow_white"
                    />
                    <img
                      src={brownRightArrow}
                      alt="Navigation arrow"
                      loading="lazy"
                      className="arrow_logo arrow_brown"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>

        <section className="villa_inner_sec4" data-aos="fade-up">
          <div className="my_container">
            <div className="row">
              <div className="col-md-8">
                <div className="text_container">
                  <div className="title_wrapper">
                    <h3 className="section_title section_title_amenities">
                      <span className="letter">A</span>
                      <span className="letter">m</span>
                      <span className="letter">e</span>
                      <span className="letter">n</span>
                      <span className="letter">i</span>
                      <span className="letter">t</span>
                      <span className="letter">i</span>
                      <span className="letter">e</span>
                      <span className="letter">s</span>
                    </h3>
                    <div className="section_title_horizantal_line_wrapper">
                      <span className="section_title_horizantal_line"></span>
                    </div>
                  </div>
                  <p
                    className="section_heading_desc"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  >
                    Get Swept Away By The Exclusive Indulgences
                  </p>
                </div>
              </div>
              <div className="col-md-4 col2">
                {width >= 768 && (
                  <div className="navigation_arrows_wrapper">
                    <div className="navigation_arrow navigation_left_arrow">
                      <img
                        src={whiteLeftArrow}
                        alt="Navigation arrow"
                        loading="lazy"
                        className="arrow_logo arrow_white"
                      />
                      <img
                        src={brownLeftArrow}
                        alt="Navigation arrow"
                        loading="lazy"
                        className="arrow_logo arrow_brown"
                      />
                    </div>
                    <div className="navigation_arrow navigation_right_arrow">
                      <img
                        src={whiteRightArrow}
                        alt="Navigation arrow"
                        loading="lazy"
                        className="arrow_logo arrow_white"
                      />
                      <img
                        src={brownRightArrow}
                        alt="Navigation arrow"
                        loading="lazy"
                        className="arrow_logo arrow_brown"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className="amenity_swiper_wrapper"
            data-aos="fade-up"
            data-aos-delay="500"
          >
            <Swiper
              modules={[Navigation, Autoplay]}
              spaceBetween={20}
              navigation={{
                prevEl: ".villa_inner_sec4 .navigation_left_arrow",
                nextEl: ".villa_inner_sec4 .navigation_right_arrow",
              }}
              // loop={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: true,
              }}
              breakpoints={amenityBreakpoints}
            >
              {amenitiesList}
            </Swiper>
            {width <= 767 && (
              <div className="navigation_arrows_wrapper">
                <div className="navigation_arrow navigation_left_arrow">
                  <img
                    src={whiteLeftArrow}
                    alt="Navigation arrow"
                    loading="lazy"
                    className="arrow_logo arrow_white"
                  />
                  <img
                    src={brownLeftArrow}
                    alt="Navigation arrow"
                    loading="lazy"
                    className="arrow_logo arrow_brown"
                  />
                </div>
                <div className="navigation_arrow navigation_right_arrow">
                  <img
                    src={whiteRightArrow}
                    alt="Navigation arrow"
                    loading="lazy"
                    className="arrow_logo arrow_white"
                  />
                  <img
                    src={brownRightArrow}
                    alt="Navigation arrow"
                    loading="lazy"
                    className="arrow_logo arrow_brown"
                  />
                </div>
              </div>
            )}
          </div>
        </section>

        <section className="villa_inner_sec5" data-aos="fade-up">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 col1">
                <div className="text_container">
                  <div className="title_wrapper">
                    <h3 className="section_title section_title_floorplan">
                      <span className="letter">F</span>
                      <span className="letter">l</span>
                      <span className="letter">o</span>
                      <span className="letter">o</span>
                      <span className="letter">r</span>&nbsp;&nbsp;
                      <span className="letter">P</span>
                      <span className="letter">l</span>
                      <span className="letter">a</span>
                      <span className="letter">n</span>
                    </h3>
                    <div className="section_title_horizantal_line_wrapper">
                      <span className="section_title_horizantal_line"></span>
                    </div>
                  </div>
                  {floorName}
                </div>
                {floorImage}
              </div>
              <div className="col-lg-6 col2">
                {width >= 540 ? (
                  <div className="floor_tabs_flex">{floorTabList}</div>
                ) : (
                  <div className="mb_floor_tab_wrapper">
                    <select
                      onChange={handleFloorTabChange}
                      className="mb_tab_dropdown"
                    >
                      {mbFloorTabList}
                    </select>
                    <img
                      src={floorDownArrow}
                      alt="floor Down Arrow"
                      className="floorDownArrow"
                      loading="lazy"
                    />
                  </div>
                )}
                {floorPointsList}
              </div>
            </div>
          </div>
        </section>

        <section className="villa_inner_sec6" data-aos="fade-up">
          <div className="my_container">
            <div className="text_container">
              <div className="title_wrapper">
                <h3 className="section_title section_title_gallery">
                  <span className="letter">V</span>
                  <span className="letter">i</span>
                  <span className="letter">e</span>
                  <span className="letter">w</span>&nbsp;&nbsp;
                  <span className="letter">G</span>
                  <span className="letter">a</span>
                  <span className="letter">l</span>
                  <span className="letter">l</span>
                  <span className="letter">e</span>
                  <span className="letter">r</span>
                  <span className="letter">y</span>
                </h3>
                <div className="section_title_horizantal_line_wrapper">
                  <span className="section_title_horizantal_line"></span>
                </div>
              </div>
              <p className="subheading" data-aos="fade-up" data-aos-delay="500">
                Villa Gallery
              </p>
            </div>
            <div
              className="villa_gallery_swiper"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <Swiper
                modules={[Pagination, Navigation, Autoplay]}
                navigation={{
                  prevEl: `.villa_gallery_swiper .navigation_left_arrow`,
                  nextEl: `.villa_gallery_swiper .navigation_right_arrow`,
                }}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: true,
                }}
                className="mySwiper"
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 50,
                  },
                  600: {
                    slidesPerView: 1.4,
                    spaceBetween: 50,
                  },
                  1194: {
                    slidesPerView: 1.7,
                    spaceBetween: 75,
                  },
                  1600: {
                    slidesPerView: 1.7,
                    spaceBetween: 95,
                  },
                }}
              >
                {galleryList}
              </Swiper>
              <div className="navigation_arrows_wrapper">
                <div className="navigation_arrow navigation_left_arrow">
                  <img
                    src={whiteLeftArrow}
                    alt="Navigation arrow"
                    loading="lazy"
                    className="arrow_logo arrow_white"
                  />
                  <img
                    src={brownLeftArrow}
                    alt="Navigation arrow"
                    loading="lazy"
                    className="arrow_logo arrow_brown"
                  />
                </div>
                <div className="navigation_arrow navigation_right_arrow">
                  <img
                    src={whiteRightArrow}
                    alt="Navigation arrow"
                    loading="lazy"
                    className="arrow_logo arrow_white"
                  />
                  <img
                    src={brownRightArrow}
                    alt="Navigation arrow"
                    loading="lazy"
                    className="arrow_logo arrow_brown"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="villa_inner_sec7" data-aos="fade-up">
          <div className="my_container">
            <div className="row flex_box">
              <div className="col-md-6 col-lg-3">
                <div className="text_container">
                  <div className="title_wrapper">
                    <h3 className="section_title section_title_projteam">
                      <span className="letter">O</span>
                      <span className="letter">u</span>
                      <span className="letter">r</span>&nbsp;&nbsp;
                      <span className="letter">P</span>
                      <span className="letter">r</span>
                      <span className="letter">o</span>
                      <span className="letter">j</span>
                      <span className="letter">e</span>
                      <span className="letter">c</span>
                      <span className="letter">t</span>&nbsp;&nbsp;
                      <span className="letter">T</span>
                      <span className="letter">e</span>
                      <span className="letter">a</span>
                      <span className="letter">m</span>
                    </h3>
                    <div className="section_title_horizantal_line_wrapper">
                      <span className="section_title_horizantal_line"></span>
                    </div>
                  </div>
                  <p
                    className="subheading"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  >
                    Meet The Team
                  </p>
                </div>
                {width > 767 ? (
                  <div
                    className="navigation_arrows_wrapper team_navigation"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  >
                    <div className="navigation_arrow navigation_left_arrow">
                      <img
                        src={whiteLeftArrow}
                        alt="Navigation arrow"
                        loading="lazy"
                        className="arrow_logo arrow_white"
                      />
                      <img
                        src={brownLeftArrow}
                        alt="Navigation arrow"
                        loading="lazy"
                        className="arrow_logo arrow_brown"
                      />
                    </div>
                    <div className="navigation_arrow navigation_right_arrow">
                      <img
                        src={whiteRightArrow}
                        alt="Navigation arrow"
                        loading="lazy"
                        className="arrow_logo arrow_white"
                      />
                      <img
                        src={brownRightArrow}
                        alt="Navigation arrow"
                        loading="lazy"
                        className="arrow_logo arrow_brown"
                      />
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="col-md-6 col-lg-9">
                <div className="team_members_swiper_wrapper">
                  <Swiper
                    modules={[Navigation, Autoplay]}
                    // autoplay={{
                    //   delay: 3000,
                    //   disableOnInteraction: false,
                    // }}
                    loop={true}
                    navigation={{
                      prevEl: `.team_navigation .navigation_left_arrow`,
                      nextEl: `.team_navigation .navigation_right_arrow`,
                    }}
                    slidesPerView={3}
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                        spaceBetween: 15,
                      },
                      600: {
                        slidesPerView: 1.2,
                        spaceBetween: 20,
                      },
                      992: {
                        slidesPerView: 2.7,
                      },
                      1700: {
                        slidesPerView: 2.4,
                      },
                    }}
                  >
                    <SwiperSlide>
                      <div className="member_box">
                        <div className="member_text_content">
                          <span
                            className="member_name"
                            data-aos="fade-up"
                            data-aos-delay="250"
                          >
                            Mr Kanhai Gandhi
                          </span>
                          <span
                            className="member_desg"
                            data-aos="fade-up"
                            data-aos-delay="250"
                          >
                            <span className="member_name_1">
                              KNS Architects,
                            </span>
                            Design Architect
                          </span>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="member_box">
                        <div className="member_text_content">
                          <span
                            className="member_name"
                            data-aos="fade-up"
                            data-aos-delay="250"
                          >
                            Mr Yeole
                          </span>
                          <span
                            className="member_desg"
                            data-aos="fade-up"
                            data-aos-delay="250"
                          >
                            Aesthetic Planners, Liasoning Architect
                          </span>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="member_box">
                        <div className="member_text_content">
                          <span
                            className="member_name"
                            data-aos="fade-up"
                            data-aos-delay="250"
                          >
                            Mr Ganesh Bhalerao
                          </span>
                          <span
                            className="member_desg"
                            data-aos="fade-up"
                            data-aos-delay="250"
                          >
                            RCC consultants, Inavit consultant
                          </span>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="member_box">
                        <div className="member_text_content">
                          <span
                            className="member_name"
                            data-aos="fade-up"
                            data-aos-delay="250"
                          >
                            Mr Milind N
                          </span>
                          <span
                            className="member_desg"
                            data-aos="fade-up"
                            data-aos-delay="250"
                          >
                            Landscape Consultant Root works
                          </span>
                        </div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
                {width < 767 ? (
                  <div className="navigation_arrows_wrapper team_navigation">
                    <div className="navigation_arrow navigation_left_arrow">
                      <img
                        src={whiteLeftArrow}
                        alt="Navigation arrow"
                        loading="lazy"
                        className="arrow_logo arrow_white"
                      />
                      <img
                        src={brownLeftArrow}
                        alt="Navigation arrow"
                        loading="lazy"
                        className="arrow_logo arrow_brown"
                      />
                    </div>
                    <div className="navigation_arrow navigation_right_arrow">
                      <img
                        src={whiteRightArrow}
                        alt="Navigation arrow"
                        loading="lazy"
                        className="arrow_logo arrow_white"
                      />
                      <img
                        src={brownRightArrow}
                        alt="Navigation arrow"
                        loading="lazy"
                        className="arrow_logo arrow_brown"
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </section>

        <section className="villa_inner_sec8">
          <div className="my_container">
            <div className="row flex_box">
              <div className="col-lg-3">
                <div className="text_container">
                  <div className="title_wrapper">
                    <h3 className="section_title section_title_map">
                      <span className="letter">O</span>
                      <span className="letter">u</span>
                      <span className="letter">r</span>&nbsp;&nbsp;
                      <span className="letter">L</span>
                      <span className="letter">o</span>
                      <span className="letter">c</span>
                      <span className="letter">a</span>
                      <span className="letter">t</span>
                      <span className="letter">i</span>
                      <span className="letter">o</span>
                      <span className="letter">n</span>
                    </h3>
                    <div className="section_title_horizantal_line_wrapper">
                      <span className="section_title_horizantal_line"></span>
                    </div>
                  </div>
                  <p
                    className="subheading"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  >
                    Address
                  </p>
                  <img
                    width="132"
                    src={eclipsebrown}
                    alt="eclipse img"
                    className="map_eclipse"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  />
                  <p
                    className="section_desc"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  >
                    IRAAH LIFESPACES 
                    <a href="https://maps.app.goo.gl/5YCt6t2TF8aYxGR7A?g_st=ic" target="_blank" className="iraah_link">
                    Gold Valley Sector D, Tungarli, <br></br> Lonavala - 410405  <br></br> Lonavala
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-lg-9">
                <div
                  className="inner_map_wrapper"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  {width > 767 ? (
                    <a href="https://maps.app.goo.gl/5YCt6t2TF8aYxGR7A?g_st=ic" target="_blank">
                  <img src={googlemap} 
                  width="100%"
                  height="597"
                  /> 
                  </a>
                  ) : (
                    <a href="https://maps.app.goo.gl/5YCt6t2TF8aYxGR7A?g_st=ic" target="_blank">
                    <img src={googlemap} 
                    width="100%"
                      height="400"
                      /> 
                      </a>
                  )}
                  {/* {width > 767 ? (
                    <iframe
                      title="google map frame for desktop"
                      src={googlemap}
                      // src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d60345.28447045212!2d73.0759168!3d19.038208!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2s!5e0!3m2!1sen!2sin!4v1675838094849!5m2!1sen!2sin"
                      width="100%"
                      height="597"
                      style={{ border: "0", margin: "0", padding: "0" }}
                      loading="lazy"
                    ></iframe>
                  ) : (
                    <iframe
                      title="google map frame for mobile"
                      src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d60345.28447045212!2d73.0759168!3d19.038208!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2s!5e0!3m2!1sen!2sin!4v1675838094849!5m2!1sen!2sin"
                      width="100%"
                      height="400"
                      style={{ border: "0", margin: "0", padding: "0" }}
                      loading="lazy"
                    ></iframe>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="villa_inner_sec9"
          id="villa_inner_sec9"
          data-aos="fade-up"
        >
          <div className="my_container">
            <div className="form_box">
              <div className="text_container">
                <div className="title_wrapper">
                  <h3 className="section_title section_title_form">
                    <span className="letter">G</span>
                    <span className="letter">e</span>
                    <span className="letter">t</span>&nbsp;&nbsp;
                    <span className="letter">I</span>
                    <span className="letter">n</span>&nbsp;&nbsp;
                    <span className="letter">T</span>
                    <span className="letter">o</span>
                    <span className="letter">u</span>
                    <span className="letter">c</span>
                    <span className="letter">h</span>
                  </h3>
                  <div className="section_title_horizantal_line_wrapper">
                    <span className="section_title_horizantal_line"></span>
                  </div>
                </div>
                <p
                  className="subheading"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  We pride ourselves on the
                </p>
              </div>
              <div className="form_container">
                <form className="form-group" onSubmit={handleSubmit}>
                  {width > 767 ? (
                    <div className="contact_email">
                      <div className="material-textfield contactus">
                        <input
                          placeholder=" "
                          type="text"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                        />
                        <label>Name*</label>
                        <div className="error">{formError.name}</div>
                      </div>
                      <div className="material-textfield emailid">
                        <input
                          type="tel"
                          placeholder=" "
                          name="phone"
                          value={values.phone}
                          onChange={handleChange}
                        />
                        <label>Phone Number*</label>
                        <div className="error">{formError.phone}</div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="material-textfield">
                        <input
                          placeholder=" "
                          type="text"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                        />
                        <label>Name*</label>
                        <div className="error">{formError.name}</div>
                      </div>
                      <div className="material-textfield">
                        <input
                          type="tel"
                          placeholder=" "
                          name="phone"
                          value={values.phone}
                          onChange={handleChange}
                        />
                        <label>Phone Number*</label>
                        <div className="error">{formError.phone}</div>
                      </div>
                    </>
                  )}
                  {width > 767 ? (
                    <div className="contact_email">
                      <div className="material-textfield emailid">
                        <input
                          placeholder=" "
                          type="email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                        />
                        <label>E-mail ID*</label>
                        <div className="error">{formError.email}</div>
                      </div>
                      <div className="material-textfield contactus">
                        <input
                          placeholder=" "
                          type="text"
                          name="message"
                          value={values.message}
                          onChange={handleChange}
                        />
                        <label>Message*</label>
                        <div className="error">{formError.message}</div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="material-textfield">
                        <input
                          placeholder=" "
                          type="email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                        />
                        <label>E-mail ID*</label>
                        <div className="error">{formError.email}</div>
                      </div>
                      <div className="material-textfield">
                        <input
                          placeholder=" "
                          type="text"
                          name="message"
                          value={values.message}
                          onChange={handleChange}
                        />
                        <label>Message*</label>
                        <div className="error">{formError.message}</div>
                      </div>
                    </>
                  )}
                  <div className="cta_wrapper">
                    <button type="submit" className="common_btn">
                      {loading ? "Sending.." : "Send"}
                      <img
                        src={ctaarrow}
                        alt="cta arrow"
                        className="cta_arrow"
                        loading="lazy"
                      />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* <FsLightbox
        toggler={toggler}
        sources={["https://www.youtube.com/embed/HHjSdy9l7Kc"]}
      /> */}
    </>
  );
};

export default Stellar;
