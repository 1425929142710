import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { ctaarrow, IraahBrochure, IraahBrochure1, iraahlogo, lonavala1, stellar8 } from "../../images";
import "./header.scss";
import HeaderNavItem from "./HeaderNavItem";
const body = document.querySelector("body");

const Header = () => {
  const [navlinkIndex, setNavlinkIndex] = useState(null);
  // const [dropIndex, setDropIndex] = useState(null);
  const { width } = useWindowDimensions();
  const { pathname: locationPathname } = useLocation();
  const [navActiveIndex, setNavActiveIndex] = useState(null);
  const [isMenuActive, setIsMenuActive] = useState(false);

  const navDataArr = [
    {
      mainTitle: "About Us",
      mainLink: "/about-us",
    },
    {
      mainTitle: "PROJECTS",
      dropDownLevel: 2,
      dropDownList: [
        {
          dropDowntitle: "Alibaug",
          linkArr: [
            {
              type: "internal",
              navTitle: "Villa Estelle",
              navLink: "/villa-estelle",
            },
            // {
            //   type: "internal",
            //   navTitle: "Villa Elanza",
            //   navLink: "/villa-elanza",
            // },
            {
              type: "internal",
              navTitle: "Villa Excelencia",
              navLink: "/villa-excelencia",
            },
          ],
        },
        {
          dropDowntitle: "Lonavala",
          linkArr: [
            {
              type: "internal",
              navTitle: "Stellar 8",
              navLink: "/stellar-8",
            },
            // {
            //   navTitle: "Villa 02",
            //   navLink: "/villa-inner",
            // },
            // {
            //   navTitle: "Villa 03",
            //   navLink: "/villa-inner",
            // },
          ],
        },
      ],
    },
    {
      mainTitle: "BeSpoke Home",
      mainLink: "/bespoke-home",
    },
    // {
    //   mainTitle: "E-BROCHURE",
    //   mainLink: IraahBrochure,
    // },
    {
      mainTitle: "E-BROCHURE",
      dropDownLevel: 1,
      dropDownList: [
        {
          type: "external",
          navTitle: "Alibaug",
          navLink: IraahBrochure1,
        },
        {
          type: "external",
          navTitle: "Lonavala",
          navLink: lonavala1,
        },
      ],
    },
    // {
    //   mainTitle: "VIRTUAL TOUR",
    //   // mainLink: "/virtual-tour",
    //   mainLink: "#",
    // },
    
    {
      mainTitle: "VIRTUAL TOUR",
      dropDownLevel: 3,
      dropDownList: [
        {
          navTitle: "Alibaug",
          navLink: "https://youtu.be/XYwujuhqrns",
        },
        {
          navTitle: "Lonavala",
          navLink: "https://www.youtube.com/watch?v=2SxKWsOB_DM",
        },
      ],
    },

    {
      mainTitle: "CONTACT US",
      mainLink: "/contact-us",
    },
  ];

  useEffect(() => {
    body.style.overflow = "auto";
    setIsMenuActive(false);
  }, [locationPathname]);

  const scrollHandler = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleHamburgerClick = () => {
    setIsMenuActive(!isMenuActive);
    if (!isMenuActive) {
      // Disable scroll
      body.style.overflow = "hidden";
    } else {
      // Enable scroll
      body.style.overflow = "auto";
    }
  };

  const handleNavIndex = (i) => {
    if (navActiveIndex === i) {
      setNavActiveIndex(null);
    } else {
      setNavActiveIndex(i);
    }
  };

  const navLinksArr = [
    {
      id: 1,
      type: "text",
      title: "About Us",
      link: "/about-us",
      classname: "link",
    },
    {
      id: 2,
      type: "text",
      drop: "dropdown",
      title: "PROJECTS",
      link: "/projects",
      classname: "nolink",
      linkArr: [
        {
          id: 1,
          linkTitle: "Dropdown 1",
          linkTo: "/",
        },
        {
          id: 2,
          linkTitle: "Dropdown 2",
          linkTo: "/",
        },
      ],
    },
    {
      id: 3,
      type: "text",
      title: "BeSpoke Home",
      link: "/bespoke-home",
      classname: "custombuilt",
    },
    {
      id: 4,
      type: "image",
      title: "",
      img: iraahlogo,
      link: "/",
      classname: "link",
    },
    {
      // id: 5,
      // type: "text",
      // title: "E - BROCHURE",
      // img: "",
      // link: IraahBrochure,
      // classname: "brochure",
      id: 5,
      type: "text",
      drop: "dropdown_1",
      title: "E - BROCHURE",
      // link: "/projects",
      classname: "nolink",
      linkArr: [
        {
          id: 1,
          linkTitle: "Dropdown 1",
          linkTo: "/",
        },
        {
          id: 2,
          linkTitle: "Dropdown 2",
          linkTo: "/",
        },
      ],
    },
    // {
    //   id: 6,
    //   type: "text",
    //   title: "VIRTUAL TOUR",
    //   // link: "/virtual-tour",
    //   classname: "link",
    // },

    {
      id: 6,
      type: "text",
      drop: "dropdown_2",
      title: "VIRTUAL TOUR",
      // link: "/projects",
      classname: "nolink",
      linkArr: [
        {
          id: 1,
          linkTitle: "Dropdown 1",
          linkTo: "/",
        },
        // {
        //   id: 2,
        //   linkTitle: "Dropdown 2",
        //   linkTo: "/",
        // },
      ],
    },
    
    {
      id: 7,
      type: "text",
      title: "CONTACT US",
      link: "/contact-us",
      classname: "link",
    },
    {
      id: 8,
      type: "text",
      title: "",
      link: "/",
      classname: "link",
    },
    {
      id: 9,
      type: "text",
      title: "",
      link: "/",
      classname: "link",
    },
  ];

  // const susList = navLinksArr.map((drop, i) => (
  //   <React.Fragment key={i}></React.Fragment>
  // ));

  const navlinkList = navLinksArr.map((nav, i) => (
    <>
      {nav.type === "text" ? (
        <li
          key={nav.id}
          onClick={() => setNavlinkIndex(i)}
          className={`nav_tab ${navlinkIndex === i ? "active" : ""} `}
        >
          {/* <NavLink to={nav.link} className={nav.classname}>
            {nav.title}
          </NavLink> */}

          {nav.drop === "dropdown_1" ? (
            <>
              <div className="dropdown_box">
                <div className="dropdown_list_item">
                  <a
                    href={IraahBrochure1}
                    className="dropdown_list_item_link"
                    target="_blank"rel="noreferrer"
                  >
                    Alibaug
                  </a>
                  {/* <img
                    src={ctaarrow}
                    alt="dropdown box up arrow"
                    loading="lazy"
                    className="dropdownboxuparrow"
                  /> */}
                </div>
                <div className="dropdown_list_item">
                  <a
                    href={lonavala1}
                    className="dropdown_list_item_link"
                    target="_blank"rel="noreferrer"
                  >
                    Lonavala
                  </a>
                  {/* <img
                    src={ctaarrow}
                    alt="dropdown box up arrow"
                    loading="lazy"
                    className="dropdownboxuparrow"
                  /> */}
                </div>
              </div>
            </>
          ) : null}

          {nav.drop === "dropdown_2" ? (
            <>
              <div className="dropdown_box">
                <div className="dropdown_list_item">
                  <a href="https://youtu.be/XYwujuhqrns" className="dropdown_list_item_link" target="_blank">
                    Alibaug
                  </a>
                </div>
                <div className="dropdown_list_item">
                  <a href="https://www.youtube.com/watch?app=desktop&v=2SxKWsOB_DM" target="_blank" className="dropdown_list_item_link">
                    Lonavala
                  </a>
                </div>
              </div>
            </>
          ) : null}

          <a
            href={nav.link}
            className={nav.classname}
            target={nav.link === IraahBrochure ? "_blank" : null}
            rel="noreferrer"
          >
            {nav.title}
          </a>

          {nav.drop === "dropdown" ? (
            <>
              <div className="dropdown_box">
                <div className="dropdown_list_item">
                  <NavLink className="dropdown_list_item_link" to="">
                    Alibaug
                  </NavLink>
                  <img
                    src={ctaarrow}
                    alt="dropdown box up arrow"
                    loading="lazy"
                    className="dropdownboxuparrow"
                  />
                  <div className="dropdown_list">
                    <div className="inside_dropdown_box">
                      <div className="inside_dropdown_list_item">
                        <NavLink
                          className="inside_dropdown_list_item_link"
                          to="/villa-estelle"
                        >
                          Villa Estelle
                        </NavLink>
                      </div>
                      <div className="inside_dropdown_list_item">
                        {/* <NavLink
                          className="inside_dropdown_list_item_link"
                          to="/villa-elanza"
                        >
                          Villa Elanza
                        </NavLink> */}
                      </div>
                      <div className="inside_dropdown_list_item">
                        <NavLink
                          className="inside_dropdown_list_item_link"
                          to="/villa-excelencia"
                        >
                          Villa Excelencia
                        </NavLink>
                      </div>
{/* <div className="inside_dropdown_list_item">
<a className="inside_dropdown_list_item_link" href="https://properties.iraahlifespaces.com/alibaug-villas">View More</a>
</div> */}
                    </div>
                  </div>
                </div>

                <div className="dropdown_list_item">
                  <NavLink className="dropdown_list_item_link" to="">
                    Lonavala
                  </NavLink>
                  <img
                    src={ctaarrow}
                    alt="dropdown box up arrow"
                    loading="lazy"
                    className="dropdownboxuparrow"
                  />
                  <div className="dropdown_list">
                    <div className="inside_dropdown_box">
                      <div className="inside_dropdown_list_item">
                        <NavLink
                          className="inside_dropdown_list_item_link"
                          to="/stellar-8"
                        >
                          Stellar 8
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="dropdown_list_item">
                  <NavLink className="dropdown_list_item_link" to="/">
                    Location 02
                  </NavLink>
                  <img
                    src={ctaarrow}
                    alt="dropdown box up arrow"
                    loading="lazy"
                    className="dropdownboxuparrow"
                  />
                  <div className="dropdown_list">
                    <div className="inside_dropdown_box">
                      <div className="inside_dropdown_list_item" key={i}>
                        <NavLink
                          className="inside_dropdown_list_item_link"
                          to="/villa-inner"
                        >
                          Location 01
                        </NavLink>
                      </div>
                      <div className="inside_dropdown_list_item" key={i}>
                        <NavLink
                          className="inside_dropdown_list_item_link"
                          to="/villa-inner"
                        >
                          Location 02
                        </NavLink>
                      </div>
                      <div className="inside_dropdown_list_item" key={i}>
                        <NavLink
                          className="inside_dropdown_list_item_link"
                          to="/villa-inner"
                        >
                          Location 03
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* <div className="dropdown_list_item">
                  <NavLink className="dropdown_list_item_link" to="/">
                    Location 03
                  </NavLink>
                  <img
                    src={ctaarrow}
                    alt="dropdown box up arrow"
                    loading="lazy"
                    className="dropdownboxuparrow"
                  />
                  <div className="dropdown_list">
                    <div className="inside_dropdown_box">
                      <div className="inside_dropdown_list_item">
                        <NavLink
                          className="inside_dropdown_list_item_link"
                          to="/villa-inner"
                        >
                          Villa 01
                        </NavLink>
                      </div>
                      <div className="inside_dropdown_list_item">
                        <NavLink
                          className="inside_dropdown_list_item_link"
                          to="/vill-inner"
                        >
                          Villa 02
                        </NavLink>
                      </div>
                      <div className="inside_dropdown_list_item">
                        <NavLink
                          className="inside_dropdown_list_item_link"
                          to="/villa-inner"
                        >
                          Villa 03
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </>
          ) : null}
        </li>
      ) : (
        <li
          key={nav.id}
          onClick={() => setNavlinkIndex(i)}
          className={`nav_tab ${navlinkIndex === i ? "active" : ""} `}
        >
          <NavLink to={nav.link} className={nav.classname}>
            <img
              className="logo_img"
              src={nav.img}
              alt="iraah logo"
              loading="lazy"
              onClick={scrollHandler}
            />
          </NavLink>
        </li>
      )}
    </>
  ));

  return (
    <>
      <header className="header_sec1">
        {width > 700 ? (
          <div className="my_container">
            <div className="navlinks">
              <ul>{navlinkList}</ul>
            </div>
          </div>
        ) : (
          <header className="header">
            <div className="logo_wrapper">
              <Link className="logo_link" to="/">
                <img
                  src={iraahlogo}
                  alt="iraah logo"
                  className="iraah_logo"
                  loading="lazy"
                />
              </Link>
            </div>
            <nav className="nav_links_wrapper">
              <div
                className={`nav_line2_wrapper_flex_wrapper ${
                  isMenuActive ? "active" : ""
                }`}
              >
                <ul className="nav_line2_wrapper_flex">
                  {navDataArr.map((navData, i) => (
                    <HeaderNavItem
                      navData={navData}
                      key={i}
                      arrIndex={i}
                      handleNavIndex={handleNavIndex}
                      navActiveIndex={navActiveIndex}
                      setIsMenuActive={setIsMenuActive}
                    />
                  ))}
                </ul>
              </div>
            </nav>

            {/* mobile UI start */}
            <div
              className={`hamburger_lines ${isMenuActive ? "active" : ""}`}
              onClick={handleHamburgerClick}
            >
              <div className="line line1"></div>
              <div className="line line2"></div>
              <div className="line line3"></div>
            </div>
          </header>
        )}
      </header>
    </>
  );
};

export default Header;
