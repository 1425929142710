import React, { useEffect, useState } from "react";
//swiper imports
import { Swiper, SwiperSlide } from "swiper/react";
import Loader from "../../components/Loader/Loader";
// import { useNavigate } from "react-router-dom";
// Import Swiper styles
// import "../../data/data";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import AOS from "aos";
import "aos/dist/aos.css";
// import required modules
import { Navigation, Pagination, Autoplay } from "swiper";
import {
  arrow1,
  arrow2,
  arrowback,
  brownLeftArrow,
  brownRightArrow,
  whiteLeftArrow,
  whiteRightArrow,
  homepagebanner1,
  homepagebanner2,
  homepagebanner3,
  projectsimg,
  custombuiltimg,
  custombuiltimg2,
  custombuiltimg3,
  thevilla,
  estelleicon1,
  estelleicon2,
  estelleicon3,
  estelleicon4,
  estelleicon5,
  estelleicon6,
  elanzaicon1,
  elanzaicon2,
  elanzaicon3,
  elanzaicon4,
  elanzaicon5,
  elanzaicon6,
  excelenciaicon1,
  excelenciaicon2,
  excelenciaicon3,
  excelenciaicon4,
  excelenciaicon5,
  excelenciaicon6,
  villaelanzahome,
  villaexcelenciahome,
  projectsstellar8,
  moderncontemporary,
  skylitbar,
  privateplungepool,
  zendesigned,
  wellcrafted,
  Ultraurban,
  ipadbanner1,
  mobilebanner1,
  ipadbanner2,
  mobilebanner2,
  ipadbanner3,
  mobilebanner3,
  newbanner1,
  newmobbanner1,
  newbanner2,
  newbanner3,
  newmobbanner2,
  newmobbanner3,
} from "../../images";
import "./homepage.scss";
import { CommonCta } from "../../components/CommonCta/CommonCta";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { isInViewPort } from "../../helpers/utils";
import { Fade } from "react-reveal";
import { locationGalleries } from "../../data/data";
import { Helmet } from "react-helmet";

const villaDetailsArr = [
  {
    id: 1,
    locationName: "Alibaug",
    villaNames: ["Villa Estelle", "villa excelencia"],
    villaLinks: [
      [{ id: 1, link: "/villa-estelle" }],
      [{ id: 2, link: "/villa-elanza" }],
      [{ id: 3, link: "/villa-excelencia" }],
    ],
    villaImgs: [
      [{ id: 1, img: projectsimg }],
      [{ id: 2, img: villaelanzahome }],
      [{ id: 3, img: villaexcelenciahome }],
    ],
    villaFeatures: [
      [
        {
          id: 1,
          featureLogo: estelleicon1,
          featureName: "Natural Mountain Water Stream",
        },
        {
          id: 2,
          featureLogo: estelleicon2,
          featureName: "Cocktail Lounge By The Stream",
        },
        {
          id: 3,
          featureLogo: estelleicon3,
          featureName: "Plunge Pool With Sunken Seating",
        },
        {
          id: 4,
          featureLogo: estelleicon4,
          featureName: "Skylit Hammock",
        },
        {
          id: 5,
          featureLogo: estelleicon5,
          featureName: "Streamside Dining Chalet",
        },
        {
          id: 6,
          featureLogo: estelleicon6,
          featureName: "Relaxing Spa Room",
        },
      ],
      [
        {
          id: 1,
          featureLogo: elanzaicon1,
          featureName: "Pool With Sunken Loungers",
        },
        {
          id: 2,
          featureLogo: elanzaicon2,
          featureName: "Cocktail Bar By The Pool",
        },
        {
          id: 3,
          featureLogo: elanzaicon3,
          featureName: "Cosy Cabana By The Pool",
        },
        {
          id: 4,
          featureLogo: elanzaicon4,
          featureName: "Zesty Tea House",
        },
        {
          id: 5,
          featureLogo: elanzaicon5,
          featureName: "Private Garden Pergola For Romantic Dining",
        },
        {
          id: 6,
          featureLogo: elanzaicon6,
          featureName: "Picturesque Walkway",
        },
      ],
      [
        {
          id: 5,
          featureLogo: excelenciaicon1,
          featureName: "Private Garden Pergola For Romantic Dining",
        },
        {
          id: 6,
          featureLogo: excelenciaicon2,
          featureName: "Picturesque Walkway",
        },
        {
          id: 7,
          featureLogo: excelenciaicon3,
          featureName: "Indoor Bar",
        },
        {
          id: 8,
          featureLogo: excelenciaicon4,
          featureName: "Personal Elevator",
        },
        {
          id: 9,
          featureLogo: excelenciaicon5,
          featureName: "Home Office With A Personal Garden",
        },
        {
          id: 10,
          featureLogo: excelenciaicon6,
          featureName: "Sky Lounge With Jacuzzi And Barbeque Pit",
        },
      ],
    ],
  },
  // {
  //   id: 2,
  //   locationName: "",
  //   villaNames: [""],
  //   villaFeatures: [[], []],
  // },

  {
    id: 2,
    locationName: "Lonavala",
    villaNames: ["Stellar 8"],
    villaLinks: [[{ id: 1, link: "/Stellar-8" }]],
    villaImgs: [[{ id: 1, img: projectsstellar8 }]],
    villaFeatures: [
      [
        {
          id: 1,
          featureLogo: moderncontemporary,
          featureName: "Modern & Contemporary Influences",
        },
        {
          id: 2,
          featureLogo: Ultraurban,
          featureName: "Ultra Urban Avant-Garde Kitchen",
        },
        {
          id: 3,
          featureLogo: privateplungepool,
          featureName: "Private Plunge Pool ",
        },
        {
          id: 4,
          featureLogo: skylitbar,
          featureName: "skylit Bar",
        },
        {
          id: 5,
          featureLogo: zendesigned,
          featureName: "Zen-Designed Bathrooms",
        },
        {
          id: 6,
          featureLogo: wellcrafted,
          featureName: "Well-Crafted Gardens",
        },
      ],
    ],
  },
];

const bannerImagesArr = [
  {
    id: "1",
    // img: banner1,
    img: newbanner1,
    iPadImg: newbanner1,
    mobImg: newmobbanner1,
    title1: "Breathe",
    title2:
      "invest in an exclusive destination tucked amidst the sea and mountain",
  },
  {
    id: "2",
    img: newbanner2,
    iPadImg: newbanner2,
    mobImg: newmobbanner2,
    title1: "Breathe",
    title2: "soak in the peace at your private villa in Alibag",
  },
  {
    id: "3",
    img: newbanner3,
    iPadImg: newbanner3,
    mobImg: newmobbanner3,
    title1: "Breathe",
    title2: "The Air Of Luxury",
  },
];

// const galleryArr = [
//   {
//     img: gallery1,
//     title: "Hiltop villa",
//   },
//   {
//     img: gallery2,
//     title: "Demo Title",
//   },
//   {
//     img: gallery3,
//     title: "Demo Title",
//   },
//   {
//     img: gallery4,
//     title: "Demo Title",
//   },
//   {
//     img: gallery5,
//     title: "Demo Title",
//   },
//   {
//     img: gallery6,
//     title: "Demo Title",
//   },
// ];

// villaDetailsArr[index]['villaNames'].map()
// villaDetailsArr[index]['villaFeatures'][index2].map()

const Homepage = () => {
  const [locationIndex, setLocationIndex] = useState(0);
  const [villaIndex, setVillaIndex] = useState(0);
  const { width } = useWindowDimensions();
  const [isLoader, setIsLoader] = useState(true);
  const [galleryTabIndex, setGalleryTabIndex] = useState(0);

  const scrollDown = () => {
    const id = "homesec2";
    const yOffset = -180;
    const element = document.getElementById(id);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  const imageLoaded = (e) => {
    if (bannerImagesArr.length) {
      if (e.target.complete && e.target.naturalHeight !== 0) {
        setIsLoader(false);
      }
    }
  };

  const bannerImgList = bannerImagesArr.map((item, i) => (
    <>
      <SwiperSlide key={item.id}>
        <div className="zoomout">
          <>
            {width > 992 && (
              <img
                className="banner_img"
                src={item.img}
                alt="banner img"
                onLoad={i === 0 ? imageLoaded : null}
              />
            )}
            {width > 600 && width <= 992 && (
              <img
                className="banner_img"
                src={item.iPadImg}
                alt="banner img"
                onLoad={i === 0 ? imageLoaded : null}
              />
            )}
            {width < 600 && (
              <img
                className="banner_img"
                src={item.mobImg}
                alt="banner img"
                onLoad={i === 0 ? imageLoaded : null}
              />
            )}
          </>
        </div>
        <div className="text_container">
          {/* <h2 className="heading">Breathe</h2>
          <p className="desc">a new way of living</p> */}
          <h1 className="title">
            <span>{item.title1}</span>
            <span>{item.title2}</span>
            {/* <span>you here</span> */}
          </h1>
          {/* {width > 600 ? (
            <div className="cta_wrapper">
              <CommonCta white btnText="View project" linkTo="villa-estelle" />
            </div>
          ) : (
            <div className="cta_wrapper">
              <CommonCta white btnText="View project" linkTo="villa-estelle" />
            </div>
          )} */}
        </div>
      </SwiperSlide>
    </>
  ));

  // const pagination = {
  //   clickable: true,
  //   renderBullet: function (index, className) {
  //     return (
  //       '<span class="' +
  //       className +
  //       '">' +
  //       0 +
  //       bannerImagesArr[index].id +
  //       "</span>"
  //     );
  //   },
  // };

  const locationTabList = villaDetailsArr.map(({ locationName }, i) => (
    <>
      <div
        key={i}
        onClick={() => setLocationIndex(i)}
        className={`location_tab ${locationIndex === i ? "active" : ""} `}
      >
        {locationName}
      </div>
    </>
  ));

  const villaList = villaDetailsArr[locationIndex]["villaNames"].map(
    (villaName, i) => (
      <>
        <div
          key={i}
          onClick={() => setVillaIndex(i)}
          className={`villa_tab ${villaIndex === i ? "active" : ""} `}
        >
          {villaName}
        </div>
      </>
    )
  );

  const villaLinkList = villaDetailsArr[locationIndex]["villaLinks"][
    villaIndex
  ]?.map((item, i) => (
    <>
      <CommonCta
        key={item.id}
        brownarrow
        btnText="View Project"
        linkTo={item.link}
      />
    </>
  ));

  const villaImgList = villaDetailsArr[locationIndex]["villaImgs"][
    villaIndex
  ]?.map((item, i) => (
    <>
      <img
        key={item.id}
        width="826"
        height="505"
        className="projectimg"
        src={item.img}
        alt="project img"
        loading="lazy"
      />
    </>
  ));

  const villaFeatureList = villaDetailsArr[locationIndex]["villaFeatures"][
    villaIndex
  ]?.map((item, i) => (
    <div className="col-6 col-md-4 col-lg-6" key={item.id}>
      <div className="content_box">
        <img
          src={item.featureLogo}
          className="icon_img"
          alt="bed icon"
          loading="lazy"
        />
        <h6 className="title">{item.featureName}</h6>
      </div>
    </div>
  ));

  // const galleryList = galleryArr.map((item, i) => (
  //   <SwiperSlide>
  //     <div className="image_wrapper" key={i}>
  //       <div className="imageanimate">
  //         <img
  //           className="gallery_img"
  //           src={item.img}
  //           width="100%"
  //           alt="gallery img"
  //           loading="lazy"
  //         />
  //       </div>
  //       <div className="title_text">{item.title}</div>
  //     </div>
  //   </SwiperSlide>
  // ));

  const galleryTab = locationGalleries.map((item, i) => (
    <span
      className={`gallery_tab ${galleryTabIndex === i ? "active" : ""}`}
      onClick={() => setGalleryTabIndex(i)}
      key={i}
    >
      {item.location}
    </span>
  ));

  const galleryList = locationGalleries[galleryTabIndex].gallery.map(
    (item, i) => (
      <SwiperSlide>
        <div className="image_wrapper" key={i}>
          <div className="imageanimate">
            <img
              className="gallery_img"
              src={item.img}
              width="100%"
              alt="gallery img"
              loading="lazy"
            />
          </div>
          <div className="title_text">{item.title}</div>
        </div>
      </SwiperSlide>
    )
  );
  useEffect(() => {
    AOS.init({
      duration: 2400,
      disable: "mobile",
    });
    window.scrollTo(0, 0);
  }, [isLoader]);

  const handleScroll = () => {
    const section_title_projects = document.querySelector(
      ".section_title_projects"
    );
    const section_title_bespoke = document.querySelector(
      ".section_title_bespoke"
    );
    const section_title_gallery = document.querySelector(
      ".section_title_gallery"
    );
    isInViewPort(
      section_title_projects,
      ".section_title_projects",
      ".home_sec3 .section_title_horizantal_line"
    );
    isInViewPort(
      section_title_bespoke,
      ".section_title_bespoke",
      ".home_sec5 .section_title_horizantal_line"
    );
    isInViewPort(
      section_title_gallery,
      ".section_title_gallery",
      ".home_sec6 .section_title_horizantal_line"
    );
  };

  useEffect(() => {
    if (villaIndex !== 0) {
      setVillaIndex(0);
    }
  }, [locationIndex]);

  const handleClick = () => {
    if (
      villaIndex <
      villaDetailsArr[locationIndex]["villaFeatures"]?.length - 1
    ) {
      setVillaIndex(villaIndex + 1);
    } else {
      setVillaIndex(0);
    }
  };

  const reversehandleClick = () => {
    if (
      villaIndex >
      villaDetailsArr[locationIndex]["villaFeatures"]?.length - 3
    ) {
      setVillaIndex(villaIndex - 1);
    } else {
      setVillaIndex(2);
    }
  };
  // const navigate = useNavigate();

  // const refreshPage = () => {
  //   navigate(0);
  // };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <div>
        <Helmet>
          <title>
            Best Real Estate Companies in Mumbai -Find Your Dream Home
          </title>
          <meta
            name="description"
            content="Looking for the best real estate companies in Mumbai? Iraahlifespaces can help you find your dream home in the bustling city. Contact us today!"
          />
          <meta name="keywords" content="real estate companies in mumbai" />
          <link rel="canonical" href="https://iraahlifespaces.com/" />
        </Helmet>
      </div>
      <div style={{ display: isLoader ? "block" : "none" }}>
        <Loader />
      </div>
      <Fade>
        <section className="home_sec1">
          <div className="banner_slides_wrapper">
            {/* {width > 600 ? (
              <> */}
            <Swiper
              modules={[Autoplay]}
              allowTouchMove={false}
              loop={false}
              speed={350}
              autoplay={{
                delay: 5000,
                disableOnInteraction: true,
              }}
              className="mySwiper"
            >
              {bannerImgList}
            </Swiper>
            <div className="scroll-downs">
              <div className="mousey" onClick={scrollDown}>
                <div className="scroller"></div>
              </div>
            </div>
            {/* </>
            ) : (
              <>
                <Swiper
                  modules={[Autoplay]}
                  allowTouchMove={false}
                  loop={true}
                  speed={500}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: true,
                  }}
                  className="mySwiper"
                >
                  {bannerImgList}
                </Swiper>
                <div className="scroll-downs">
                  <div className="mousey" onClick={scrollDown}>
                    <div className="scroller"></div>
                  </div>
                </div>
              </>
            )} */}
          </div>
        </section>
      </Fade>

      <section className="home_sec2" id="homesec2" data-aos="fade-up">
        <div className="my_container">
          <div className="row flex_box">
            <div className="col-md-6 col-lg-3">
              <div className="text_container">
                {/* <div className="title_wrapper">
                  <h3 className="section_title section_title_the_villa">
                    <span className="letter">T</span>
                    <span className="letter">h</span>
                    <span className="letter">e</span>&nbsp;&nbsp;
                    <span className="letter">V</span>
                    <span className="letter">i</span>
                    <span className="letter">l</span>
                    <span className="letter">l</span>
                    <span className="letter">a</span>
                  </h3>
                  <div className="section_title_horizantal_line_wrapper">
                    <span className="section_title_horizantal_line"></span>
                  </div>
                </div> */}
                <p
                  className="subheading"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  Finest Lifestyle For The Finest Few
                </p>
                {width > 600 ? (
                  <div
                    className="cta_wrapper"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  >
                    <CommonCta
                      brownarrow
                      btnText="Know More"
                      linkTo="/about-us"
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-md-6 col-lg-5">
              <div className="imageanimate">
                <img
                  src={thevilla}
                  width="100%"
                  height="100%"
                  alt="iraah img"
                  loading="lazy"
                  className="pool_img"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="text_container">
                <p className="desc2" data-aos="fade-up" data-aos-delay="500">
                  At Iraah, our passion is to create landmarks that personify
                  luxury, meet international standards, and are built on a
                  legacy of trust. Being synonymous with innovation, we create a
                  lasting first impression with all our developments. From
                  destination to architecture, workmanship to cutting edge
                  technology, we build the most opulent homes in harmony with
                  nature, each of which embodies your unique lifestyle.
                </p>
              </div>
              {width < 600 ? (
                <div className="cta_wrapper">
                  <CommonCta
                    brownarrow
                    btnText="Know More"
                    linkTo="/about-us"
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </section>

      <section className="home_sec3" data-aos="fade-up">
        {width > 600 ? (
          <div className="row backgroundcolor">
            <div className="col-lg-6 first_container">
              <div className="text_container">
                <div className="title_wrapper">
                  <h3 className="section_title section_title_projects">
                    <span className="letter">P</span>
                    <span className="letter">r</span>
                    <span className="letter">o</span>
                    <span className="letter">j</span>
                    <span className="letter">e</span>
                    <span className="letter">c</span>
                    <span className="letter">t</span>
                    <span className="letter">s</span>
                  </h3>
                  <div className="section_title_horizantal_line_wrapper">
                    <span className="section_title_horizantal_line"></span>
                  </div>
                </div>
                <p
                  className="subheading"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  Step Into The World Of Iraah’s Luxurious Collection
                </p>
                <div
                  className="cta_wrapper"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  {villaLinkList}
                </div>
              </div>

              <div className="imageanimate">{villaImgList}</div>
              {locationIndex === 0 ? (
                <>
                  <img
                    src={arrowback}
                    alt="arrowicon"
                    className="double_arrow"
                    loading="lazy"
                  />
                  <img
                    src={arrow2}
                    alt="arrowicon"
                    className="right_arrow"
                    loading="lazy"
                    onClick={handleClick}
                  />
                  <img
                    src={arrow1}
                    alt="arrowicon"
                    className="left_arrow"
                    loading="lazy"
                    onClick={reversehandleClick}
                  />
                </>
              ) : (
                ""
              )}
            </div>
            <div className="col-lg-6 second_container">
              <div className="tab_container">{locationTabList}</div>
              <div className="villa_container">{villaList}</div>
              <div className="row">{villaFeatureList}</div>
            </div>
          </div>
        ) : (
          <div className="my_container">
            <div className="row backgroundcolor">
              <div className="col-lg-6 first_container">
                <div className="text_container">
                  <div className="title_wrapper">
                    <h3 className="section_title section_title_projects">
                      <span className="letter">P</span>
                      <span className="letter">r</span>
                      <span className="letter">o</span>
                      <span className="letter">j</span>
                      <span className="letter">e</span>
                      <span className="letter">c</span>
                      <span className="letter">t</span>
                      <span className="letter">s</span>
                    </h3>
                    <div className="section_title_horizantal_line_wrapper">
                      <span className="section_title_horizantal_line"></span>
                    </div>
                  </div>
                  <p className="subheading">
                    Step Into The World Of Iraah’s Luxurious Collection
                  </p>
                  <div className="cta_wrapper">{villaLinkList}</div>
                </div>
                {villaImgList}
                <img
                  src={arrowback}
                  alt="arrowicon"
                  className="double_arrow"
                  loading="lazy"
                />
                <img
                  src={arrow2}
                  alt="arrowicon"
                  className="right_arrow"
                  loading="lazy"
                  onClick={handleClick}
                />
                <img
                  src={arrow1}
                  alt="arrowicon"
                  className="left_arrow"
                  loading="lazy"
                  onClick={reversehandleClick}
                />
              </div>

              <div className="col-lg-6 second_container">
                <div className="tab_container">{locationTabList}</div>
                <div className="villa_container">{villaList}</div>
                <div className="row">{villaFeatureList}</div>
              </div>
            </div>
          </div>
        )}
      </section>

      <section className="home_sec4" data-aos="fade-up">
        <div className="my_container">
          <div className="text_container">
            <h2 className="journey_heading">Our Journey</h2>
          </div>
          <div className="journey_swiper">
            <Swiper
              modules={[Navigation, Autoplay]}
              navigation={{
                prevEl: `.journey_swiper .navigation_left_arrow`,
                nextEl: `.journey_swiper .navigation_right_arrow`,
              }}
              autoplay={{
                delay: 5000,
                disableOnInteraction: true,
              }}
              loop={true}
              className="journeyswiper"
            >
              <SwiperSlide>
                <div className="text_container">
                  <h2 className="numheading">20</h2>
                  <p className="desc">
                    Years of&nbsp;
                    <strong>
                      Building
                      <br />
                      Finesse&nbsp;
                    </strong>
                    Homes
                  </p>
                </div>
                <p className="description for_margin">
                  Since 2002, with a mind full of dreams and a heart full of
                  passion, Iraah is shaping the lifestyle landmarks
                  <br />
                  with a whole new idea of space, privacy, and luxury.
                </p>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper_box">
                  <div className="text_container">
                    {/* <h2 className="numheading">5</h2> */}
                    <p className="desc bespoke_desc">
                      Bespoke Homes
                      <br />
                      <strong>Specially Curated</strong>
                      <br />
                      For You
                    </p>
                  </div>
                  <p className="description">
                    Beyond imagination, just for you in reality. Iraah has in
                    store for you one of the finest masterpieces <br />
                    in the history of custom-built infrastructure, where every
                    detail will be according to your likes, every corner will be
                    of your <br />
                    interest and finely constructed to promote customization as
                    the art of new-age living.
                  </p>
                </div>
              </SwiperSlide>
            </Swiper>
            <div className="navigation_arrows_wrapper">
              <div className="navigation_arrow navigation_left_arrow">
                <img
                  src={whiteLeftArrow}
                  alt="Navigation arrow"
                  loading="lazy"
                  className="arrow_logo arrow_white"
                />
                <img
                  src={brownLeftArrow}
                  alt="Navigation arrow"
                  loading="lazy"
                  className="arrow_logo arrow_brown"
                />
              </div>
              <div className="navigation_arrow navigation_right_arrow">
                <img
                  src={whiteRightArrow}
                  alt="Navigation arrow"
                  loading="lazy"
                  className="arrow_logo arrow_white"
                />
                <img
                  src={brownRightArrow}
                  alt="Navigation arrow"
                  loading="lazy"
                  className="arrow_logo arrow_brown"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home_sec5" data-aos="fade-up">
        <div className="row backgroundcolor">
          <div className="col-lg-7 first_container">
            <div className="text_container">
              <div className="title_wrapper">
                <h3 className="section_title section_title_bespoke">
                  <span className="letter">B</span>
                  <span className="letter">e</span>
                  <span className="letter">s</span>
                  <span className="letter">p</span>
                  <span className="letter">o</span>
                  <span className="letter">k</span>
                  <span className="letter">e</span>&nbsp;&nbsp;
                  <span className="letter">H</span>
                  <span className="letter">o</span>
                  <span className="letter">m</span>
                  <span className="letter">e</span>
                  <span className="letter">s</span>
                </h3>
                <div className="section_title_horizantal_line_wrapper">
                  <span className="section_title_horizantal_line"></span>
                </div>
              </div>
              <p className="subheading" data-aos="fade-up" data-aos-delay="500">
                Specially Curated For You
              </p>
              <p className="desc" data-aos="fade-up" data-aos-delay="500">
                Beyond imagination, just for you in reality. Iraah has in store
                for you one of the finest masterpieces in the history of
                custom-built infrastructure, where every detail will be
                according to your likes, every corner will be of your interest
                and finely constructed to promote customization as the art of
                new-age living.
              </p>
              <div
                className="cta_wrapper"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <CommonCta btnText="Know More" linkTo="/bespoke-home" />
              </div>
            </div>
          </div>
          <div className="col-lg-5 second_container">
            {/* <div className="tab_container">{locationTabList}</div>
            <div className="villa_tab">
              <span className="tab_name">Villa 01</span>
              <span className="tab_name">Villa 02</span>
              <span className="tab_name">Villa 03</span>
            </div>
            <div className="row">{villaFeatureList}</div> */}
            <div className="photocol_frame">
              <div className="left_box">
                <div className="imageanimate">
                  <img
                    src={custombuiltimg}
                    className="left_img"
                    alt="iraah img"
                    loading="lazy"
                  />
                </div>
                <div className="imageanimate">
                  <img
                    src={custombuiltimg2}
                    className="left_img2"
                    alt="iraah img"
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="imageanimate">
                <img
                  src={custombuiltimg3}
                  className="right_img"
                  alt="iraah img"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home_sec6" data-aos="fade-up">
        <div className="my_container">
          <div className="text_container">
            <div className="title_wrapper">
              <h3 className="section_title section_title_gallery">
                <span className="letter">V</span>
                <span className="letter">i</span>
                <span className="letter">e</span>
                <span className="letter">w</span>&nbsp;&nbsp;
                <span className="letter">G</span>
                <span className="letter">a</span>
                <span className="letter">l</span>
                <span className="letter">l</span>
                <span className="letter">e</span>
                <span className="letter">r</span>
                <span className="letter">y</span>
              </h3>
              <div className="section_title_horizantal_line_wrapper">
                <span className="section_title_horizantal_line"></span>
              </div>
            </div>
            <p className="subheading" data-aos="fade-up" data-aos-delay="500">
              Uniquely designed for you
            </p>
          </div>
          <div className="gallery_tabs_flex">{galleryTab}</div>
          <div className="gallery_swiper">
            <Swiper
              pagination={{
                type: "progressbar",
              }}
              modules={[Pagination, Navigation, Autoplay]}
              navigation={{
                prevEl: `.gallery_swiper .navigation_left_arrow`,
                nextEl: `.gallery_swiper .navigation_right_arrow`,
              }}
              autoplay={{
                delay: 3000,
                disableOnInteraction: true,
              }}
              loopt={true}
              className="mySwiper"
              breakpoints={{
                0: {
                  slidesPerView: 1.1,
                  spaceBetween: 20,
                },
                600: {
                  slidesPerView: 1.4,
                  spaceBetween: 50,
                },
                1194: {
                  slidesPerView: 1.7,
                  spaceBetween: 75,
                },
                1600: {
                  slidesPerView: 1.7,
                  spaceBetween: 95,
                },
              }}
            >
              {galleryList}
            </Swiper>
            <div className="swiper-pagination"></div>
            <div className="navigation_arrows_wrapper">
              <div className="navigation_arrow navigation_left_arrow">
                <img
                  src={whiteLeftArrow}
                  alt="Navigation arrow"
                  loading="lazy"
                  className="arrow_logo arrow_white"
                />
                <img
                  src={brownLeftArrow}
                  alt="Navigation arrow"
                  loading="lazy"
                  className="arrow_logo arrow_brown"
                />
              </div>
              <div className="navigation_arrow navigation_right_arrow">
                <img
                  src={whiteRightArrow}
                  alt="Navigation arrow"
                  loading="lazy"
                  className="arrow_logo arrow_white"
                />
                <img
                  src={brownRightArrow}
                  alt="Navigation arrow"
                  loading="lazy"
                  className="arrow_logo arrow_brown"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Homepage;
