import React, { useEffect } from "react";
import "./AboutUs.scss";

import AOS from "aos";
import "aos/dist/aos.css";
//swiper imports
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

//components
import Banner from "../../components/Banner/Banner";
import { Helmet } from "react-helmet";

//images
import {
  bannerimg,
  brownLeftArrow,
  brownRightArrow,
  experience,
  ourcomp,
  passion,
  quality,
  slide1,
  slide2,
  slide3,
  vision1,
  vision2,
  vision3,
  visionbg1,
  whiteLeftArrow,
  whiteRightArrow,
  whoimg1,
  whoimg2,
} from "../../images";
import { CommonCta } from "../../components/CommonCta/CommonCta";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { animateEle, isInViewPort } from "../../helpers/utils";

const AboutUs = () => {
  const { width } = useWindowDimensions();

  useEffect(() => {
    AOS.init({
      duration: 2400,
      disable: "mobile",
    });
    window.scrollTo(0, 0);

    animateEle(
      ".section_title_who_we_are",
      ".aboutsec2 .section_title_horizantal_line"
    );
  }, []);

  const handleScroll = () => {
    const section_title_our_company = document.querySelector(
      ".section_title_our_company"
    );
    const section_title_our_philosophy = document.querySelector(
      ".section_title_our_philosophy"
    );
    const section_title_our_team = document.querySelector(
      ".section_title_our_team"
    );
    const section_title_our_vision = document.querySelector(
      ".section_title_our_vision"
    );
    const section_title_why_iraah = document.querySelector(
      ".section_title_why_iraah"
    );
    isInViewPort(
      section_title_our_philosophy,
      ".section_title_our_philosophy",
      ".aboutsec3 .section_title_horizantal_line"
    );
    isInViewPort(
      section_title_our_company,
      ".section_title_our_company",
      ".aboutsec4 .section_title_horizantal_line"
    );
    isInViewPort(
      section_title_our_team,
      ".section_title_our_team",
      ".aboutsec5 .section_title_horizantal_line"
    );
    isInViewPort(
      section_title_our_vision,
      ".section_title_our_vision",
      ".aboutsec6 .section_title_horizantal_line"
    );
    isInViewPort(
      section_title_why_iraah,
      ".section_title_why_iraah",
      ".aboutsec7 .section_title_horizantal_line"
    );
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <main>
        <Banner
          imgSrc={bannerimg}
          headingText="About Iraah"
          linkTwoText="About Iraah"
          linkTwoDisabled
        />

        <div>
        <Helmet>
        <title>Expert Real Estate Builders and Developers in Mumbai</title>
        <meta name="description" content="We are real estate developers in Mumbai, committed to creating exceptional properties that redefine luxury living and shape the skyline." />
        <h1 style="position: absolute; margin-left: 2000px;">real estate builders and developers in mumbai</h1>
        <link rel="canonical" href="https://iraahlifespaces.com/about-us" />
        </Helmet>
        </div>

        <section className="aboutsec2" data-aos="fade-up">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-7 col1">
                <div className="text_container">
                  <div className="title_wrapper">
                    <h3 className="section_title section_title_who_we_are">
                      <span className="letter">W</span>
                      <span className="letter">h</span>
                      <span className="letter">o</span>&nbsp;&nbsp;
                      <span className="letter">W</span>
                      <span className="letter">e</span>&nbsp;&nbsp;
                      <span className="letter">A</span>
                      <span className="letter">r</span>
                      <span className="letter">e</span>
                    </h3>
                    <div className="section_title_horizantal_line_wrapper">
                      <span className="section_title_horizantal_line"></span>
                    </div>
                  </div>
                  <p
                    className="section_heading_desc"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  >
                    Unearthing Luxury, Sophistication & Peace Of Mind
                  </p>
                </div>
                <div className="row subrow1">
                  <div className="col-lg-6 subcol1">
                    <div className="imageanimate">
                      <img
                        src={whoimg1}
                        alt="Who we are img 1"
                        className="whoimg1"
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 subcol2">
                    <p
                      className="section_desc"
                      data-aos="fade-up"
                      data-aos-delay="500"
                    >
                      With decades of experience, Iraah is synonymous with
                      perfection and luxury. Built on the principle of ‘Unearth
                      Abundance’, homes by Iraah bring alive a multitude of
                      experiences, geared to help one live their best life and a
                      new standard of living. Today, in its pursuit to reach
                      greater heights and create more luxurious benchmarks,
                      Iraah brings its expertise to create homes that are quite
                      a statement of sophistication and exclusiveness for a
                      selected few.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col2">
                <div className="zoomout">
                  <img
                    src={whoimg2}
                    alt="Who we are img 2"
                    className="whoimg2"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="aboutsec3" data-aos="fade-up">
          <div className="content_wrapper">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-5 col1">
                  <div className="text_container">
                    {/* <h3 className="section_title">Our Philosophy</h3> */}
                    <div className="title_wrapper">
                      <h3 className="section_title section_title_our_philosophy">
                        <span className="letter">O</span>
                        <span className="letter">u</span>
                        <span className="letter">r</span> &nbsp;&nbsp;
                        <span className="letter">P</span>
                        <span className="letter">h</span>
                        <span className="letter">i</span>
                        <span className="letter">l</span>
                        <span className="letter">o</span>
                        <span className="letter">s</span>
                        <span className="letter">o</span>
                        <span className="letter">p</span>
                        <span className="letter">h</span>
                        <span className="letter">y</span>
                      </h3>
                      <div className="section_title_horizantal_line_wrapper">
                        <span className="section_title_horizantal_line"></span>
                      </div>
                    </div>
                    <p
                      className="section_heading_desc"
                      data-aos="fade-up"
                      data-aos-delay="500"
                    >
                      “The Future Depends On What We Do Today”
                    </p>
                  </div>
                </div>
                <div className="col-lg-7 col2">
                  <p
                    className="section_desc"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  >
                    The Iraah philosophy is our road map to build a sustainable
                    business in this unchartered journey. This road map is
                    entrenched in our mission, vision and values that drive us
                    to evolve, innovate and enhance all our services while
                    maintaining long-term relationships with all stakeholders.
                    We are a value and culture-driven organisation where
                    commitment, fairness, honesty, reliability and excellence
                    play an essential part in our strategy.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="aboutsec4" data-aos="fade-up">
          <div className="words_wrapper"></div>
          <div className="my_container">
            <div className="row">
              <div className="col-lg-4 col1 order-lg-2">
                <div className="text_container">
                  {/* <h3 className="section_title">our compnany</h3> */}
                  <div className="title_wrapper title_wrapper_our_company">
                    <h3 className="section_title section_title_our_company">
                      <span className="letter">O</span>
                      <span className="letter">u</span>
                      <span className="letter">r</span> &nbsp;&nbsp;
                      <span className="letter">C</span>
                      <span className="letter">o</span>
                      <span className="letter">m</span>
                      <span className="letter">p</span>
                      <span className="letter">a</span>
                      <span className="letter">n</span>
                      <span className="letter">y</span>
                    </h3>
                    <div className="section_title_horizantal_line_wrapper">
                      <span className="section_title_horizantal_line"></span>
                    </div>
                  </div>
                  <p
                    className="section_heading_desc"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  >
                    Connoisseurs Of Crafting An Exclusive Lifestyle
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col2 order-lg-1">
                <div className="imageanimate">
                  <img
                    src={ourcomp}
                    alt="Our company"
                    loading="lazy"
                    className="ourcompimg"
                  />
                </div>
              </div>
              <div className="col-lg-4 col3 order-lg-3">
                <p
                  className="section_desc"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  Incepted in 2002, Sanaya Realities Pvt. Ltd brings together
                  modernity, comfort, luxury and an enigmatic experience with
                  all its developments. Associating with a dynamic and
                  experienced team, Sanaya Realties is steadily making its
                  worthy presence in the realty sector with premium quality
                  infrastructure, timeless designs, innovation & technology,
                  modern-day amenities and seamless connectivity to enrich lives
                  while changing the face of the nation.
                </p>
                <div
                  className="btn_wrapper"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  <CommonCta
                    linkTo="http://www.sanayarealties.com/Index.aspx"
                    brownlink
                    browncta
                    btnText="Visit Website"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="aboutsec5" data-aos="fade-up">
          <div className="my_container">
            <div className="row">
              <div className="col-lg-7 col1">
                <div className="text_container">
                  <div className="title_wrapper">
                    <h3 className="section_title section_title_our_team">
                      <span className="letter">O</span>
                      <span className="letter">u</span>
                      <span className="letter">r</span> &nbsp;&nbsp;
                      <span className="letter">T</span>
                      <span className="letter">e</span>
                      <span className="letter">a</span>
                      <span className="letter">m</span>
                    </h3>
                    <div className="section_title_horizantal_line_wrapper">
                      <span className="section_title_horizantal_line"></span>
                    </div>
                  </div>
                  <p
                    className="section_heading_desc"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  >
                    Industry Wizards Working Towards Creating Eccentric
                    Masterpieces
                  </p>
                </div>
              </div>
              <div className="col-lg-5 col2">
                <p
                  className="section_desc"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  Iraah has a highly qualified and well-trained workforce that
                  focuses on quality, environmental preservation, and technology
                  adaptability while nurturing interactive ties with the client
                  community. Their unwavering commitment to sustainability and
                  innovation has positioned them to set new benchmarks.
                </p>
              </div>
            </div>
          </div>

          <div
            className="team_members_swiper_wrapper"
            data-aos="fade-up"
            data-aos-delay="500"
          >
            <Swiper
              modules={[Navigation]}
              navigation={{
                prevEl: `.team_members_swiper_wrapper .navigation_left_arrow`,
                nextEl: `.team_members_swiper_wrapper .navigation_right_arrow`,
              }}
              slidesPerView={3}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 15,
                },
                768: {
                  slidesPerView: 1.5,
                  spaceBetween: 0,
                },
                992: {
                  slidesPerView: 2,
                },
                1194: {
                  slidesPerView: 3,
                },
              }}
            >
              <SwiperSlide>
                <div className="member_box">
                  <div className="imageanimate">
                    <img
                      src={slide1}
                      alt="Team member"
                      className="member_img"
                      loading="lazy"
                      width={439}
                      height={539}
                    />
                  </div>
                  <div className="member_text_content">
                    <span className="member_name">Name of the member</span>
                    <span className="member_desg">CEO</span>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="member_box">
                  <div className="imageanimate">
                    <img
                      src={slide2}
                      alt="Team member"
                      className="member_img"
                      loading="lazy"
                      width={439}
                      height={539}
                    />
                  </div>
                  <div className="member_text_content">
                    <span className="member_name">Name of the member</span>
                    <span className="member_desg">CEO</span>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="member_box">
                  <div className="imageanimate">
                    <img
                      src={slide3}
                      alt="Team member"
                      className="member_img"
                      loading="lazy"
                      width={439}
                      height={539}
                    />
                  </div>
                  <div className="member_text_content">
                    <span className="member_name">Name of the member</span>
                    <span className="member_desg">CEO</span>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>

            <div className="navigation_arrows_wrapper">
              <div className="navigation_arrow navigation_left_arrow">
                <img
                  src={whiteLeftArrow}
                  alt="Navigation arrow"
                  loading="lazy"
                  className="arrow_logo arrow_white"
                />
                <img
                  src={brownLeftArrow}
                  alt="Navigation arrow"
                  loading="lazy"
                  className="arrow_logo arrow_brown"
                />
              </div>
              <div className="navigation_arrow navigation_right_arrow">
                <img
                  src={whiteRightArrow}
                  alt="Navigation arrow"
                  loading="lazy"
                  className="arrow_logo arrow_white"
                />
                <img
                  src={brownRightArrow}
                  alt="Navigation arrow"
                  loading="lazy"
                  className="arrow_logo arrow_brown"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="aboutsec6" data-aos="fade-up">
          <div className="greybg"></div>
          <img
            src={visionbg1}
            alt="vision img"
            loading="lazy"
            className="visionbg"
          />
          <div className="row">
            <div className="col-lg-7 col1">
              <div className="row subrow">
                <div className="col-6">
                  <div className="imaganimate">
                    <img
                      src={vision2}
                      alt=""
                      className="visionimg488"
                      loading="lazy"
                      width={488}
                      height={344}
                    />
                  </div>
                </div>
                <div className="col-6 subcol2">
                  <div className="imaganimate">
                    <img
                      src={vision1}
                      alt=""
                      className="visionimg418"
                      loading="lazy"
                      width={418}
                      height={344}
                    />
                  </div>
                </div>
              </div>
              <div className="imaganimate">
                <img
                  src={vision3}
                  alt=""
                  className="vision_bigimg"
                  loading="lazy"
                  width={944}
                  height={418}
                />
              </div>
            </div>
            <div className="col-lg-5 col2">
              <div className="text_container">
                <div className="title_wrapper">
                  <h3 className="section_title section_title_our_vision">
                    <span className="letter">O</span>
                    <span className="letter">u</span>
                    <span className="letter">r</span> &nbsp;&nbsp;
                    <span className="letter">V</span>
                    <span className="letter">i</span>
                    <span className="letter">s</span>
                    <span className="letter">i</span>
                    <span className="letter">o</span>
                    <span className="letter">n</span>
                  </h3>
                  <div className="section_title_horizantal_line_wrapper">
                    <span className="section_title_horizantal_line"></span>
                  </div>
                </div>
                <p
                  className="section_heading_desc"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  “Don’t Just Dream It, Live It”
                </p>
                <p
                  className="section_desc"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  To be a brand recognised as an industry leader for mindfully
                  planned innovative spaces, exceptional style, increasing
                  customer satisfaction, and offering a landmark living
                  environment inspired by an eco-friendly approach and the
                  preservation of natural resources for future generations.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="aboutsec7" data-aos="fade-up">
          <div className="my_container">
            <div className="row">
              <div className="col-md-8">
                <div className="text_container">
                  <div className="title_wrapper">
                    <h3 className="section_title section_title_why_iraah">
                      <span className="letter">W</span>
                      <span className="letter">h</span>
                      <span className="letter">y</span>&nbsp;&nbsp;
                      <span className="letter">C</span>
                      <span className="letter">h</span>
                      <span className="letter">o</span>
                      <span className="letter">o</span>
                      <span className="letter">s</span>
                      <span className="letter">e</span>&nbsp;&nbsp;
                      <span className="letter">I</span>
                      <span className="letter">r</span>
                      <span className="letter">a</span>
                      <span className="letter">a</span>
                      <span className="letter">h</span>
                      <span className="letter">?</span>
                    </h3>
                    <div className="section_title_horizantal_line_wrapper">
                      <span className="section_title_horizantal_line"></span>
                    </div>
                  </div>
                  <p className="section_heading_desc">
                    {/* We pride ourselves on the reliability that comes from */}
                  </p>
                </div>
              </div>
              <div className="col-md-4 col2">
                {width >= 768 && (
                  <div className="navigation_arrows_wrapper">
                    <div className="navigation_arrow navigation_left_arrow">
                      <img
                        src={whiteLeftArrow}
                        alt="Navigation arrow"
                        loading="lazy"
                        className="arrow_logo arrow_white"
                      />
                      <img
                        src={brownLeftArrow}
                        alt="Navigation arrow"
                        loading="lazy"
                        className="arrow_logo arrow_brown"
                      />
                    </div>
                    <div className="navigation_arrow navigation_right_arrow">
                      <img
                        src={whiteRightArrow}
                        alt="Navigation arrow"
                        loading="lazy"
                        className="arrow_logo arrow_white"
                      />
                      <img
                        src={brownRightArrow}
                        alt="Navigation arrow"
                        loading="lazy"
                        className="arrow_logo arrow_brown"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="usps_swiper_wrapper">
              <Swiper
                modules={[Navigation]}
                slidesPerView={3}
                spaceBetween={20}
                navigation={{
                  prevEl: ".aboutsec7 .navigation_left_arrow",
                  nextEl: ".aboutsec7 .navigation_right_arrow",
                }}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                  768: {
                    slidesPerView: 1.5,
                    spaceBetween: 0,
                  },
                  992: {
                    slidesPerView: 2,
                  },
                  1194: {
                    slidesPerView: 3,
                  },
                }}
              >
                <SwiperSlide>
                  <div className="usp_box">
                    <img
                      src={experience}
                      alt=""
                      loading="lazy"
                      className="usplogo"
                    />
                    <div
                      className="usp_name"
                      data-aos="fade-up"
                      data-aos-delay="500"
                    >
                      Experience
                    </div>
                    <div
                      className="usp_desc section_desc"
                      data-aos="fade-up"
                      data-aos-delay="500"
                    >
                      With over decades of knowledge, experience, innovation and
                      practicality, our projects evolve from a vision to
                      luxurious developments.
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="usp_box">
                    <img
                      src={quality}
                      alt=""
                      loading="lazy"
                      className="usplogo"
                    />
                    <div
                      className="usp_name"
                      data-aos="fade-up"
                      data-aos-delay="500"
                    >
                      High-Quality Services{" "}
                    </div>
                    <div
                      className="usp_desc section_desc"
                      data-aos="fade-up"
                      data-aos-delay="500"
                    >
                      With a deep understanding of consumer needs and demands,
                      we have been able to deliver homes that suit the consumer
                      segment.
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="usp_box">
                    <img
                      src={passion}
                      alt=""
                      loading="lazy"
                      className="usplogo"
                    />
                    <div
                      className="usp_name"
                      data-aos="fade-up"
                      data-aos-delay="500"
                    >
                      True Passion
                    </div>
                    <div
                      className="usp_desc section_desc"
                      data-aos="fade-up"
                      data-aos-delay="500"
                    >
                      Our ability to create these magnificent homes stems from
                      the fact that the Iraah Family consists of ardent,
                      imaginative, and creative minds.
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
              {width <= 767 && (
                <div className="navigation_arrows_wrapper">
                  <div className="navigation_arrow navigation_left_arrow">
                    <img
                      src={whiteLeftArrow}
                      alt="Navigation arrow"
                      loading="lazy"
                      className="arrow_logo arrow_white"
                    />
                    <img
                      src={brownLeftArrow}
                      alt="Navigation arrow"
                      loading="lazy"
                      className="arrow_logo arrow_brown"
                    />
                  </div>
                  <div className="navigation_arrow navigation_right_arrow">
                    <img
                      src={whiteRightArrow}
                      alt="Navigation arrow"
                      loading="lazy"
                      className="arrow_logo arrow_white"
                    />
                    <img
                      src={brownRightArrow}
                      alt="Navigation arrow"
                      loading="lazy"
                      className="arrow_logo arrow_brown"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default AboutUs;
