import React, { useEffect } from "react";

import "./ThankYou.scss";
import { thank } from "../../images";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const ThankYou = () => {
  useEffect(() => {
    AOS.init({
      duration: 2400,
      disable: "mobile",
    });
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="thankyousec" data-aos="fade">
        <div className="thankyou_container">
          <div className="headp">
            <img
              className="thankyouimg1"
              src={thank}
              alt="thankyou img"
              loading="lazy"
            />
            <h1 className="heading">Thankyou!</h1>
            <h1 className="heading2">For Getting In Touch</h1>
            <div>
              <p className="link_p">
                Please return to{" "}
                <Link className="orange_link" to="/">
                  Home page
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ThankYou;
