import React from "react";
import { NavLink } from "react-router-dom";
import { ctaarrow, wctaarrow } from "../../images";
import "./commoncta.scss";

export const CommonCta = ({
  brownarrow,
  linkTo,
  brown,
  white,
  browncta,
  btnText,
  brownlink,
  removebg,
}) => {
  if (brownarrow) {
    return (
      <NavLink
        to={linkTo ? linkTo : "/"}
        className={`common_btn ${brown ? "brown_filling" : "white_filling"}`}
      >
        {btnText}
        <img
          src={ctaarrow}
          alt="cta arrow"
          className="cta_arrow"
          loading="lazy"
        />
      </NavLink>
    );
  }
  return (
    <a
      href={linkTo ? linkTo : null}
      className={`common_btn ${white ? "whitetext" : "white_filling"} ${
        brownlink ? "brown_filling2" : "white_filling"
      } ${removebg ? "removebg" : null}`}
    >
      {btnText}
      <img
        src={`${browncta ? ctaarrow : wctaarrow}`}
        alt="cta arrow"
        className="cta_arrow"
        loading="lazy"
      />
    </a>
  );
};
